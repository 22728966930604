
import { defineComponent, computed, onMounted, ref, reactive, watch, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import copy from 'copy-to-clipboard';
import { useStore } from 'vuex';
import { useDepartment } from '@/views/main/department/utils';
import { useAuto } from '@/views/auto/utils';
import { useScene } from '@/views/scene/utils';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { useState } from '@/store';

export default defineComponent<{}, {}, {}, {}, any>({
  name: 'InviteCreateView',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const state = useState();
    const autoState = useAuto();
    const departmentState = useDepartment();
    const sceneState = useScene();

    const submiting = ref(false);
    const current = ref(0);
    const detail = ref({
      enterpriseName: '---',
      code: 'AAAA',
      url: 'https://ex.com',
    });

    const localSceneList = computed(() => sceneState.sceneList.value.map((i: any) => ({
      value: i.confNo,
      label: i.title,
      key: i.confNo,
    })));

    const formData = ref({
      invitationName: '',
      examineState: 'NEED',
      enterpriseId: state.enterprise.value.enterpriseId,
      departmentIds: undefined,
      restrictConfNo: undefined,
      vehicleId: [],
    });

    const rules = {
      invitationName: [{ required: true, message: '请输入邀请名称', trigger: 'change' }],
    };

    const editorFormRef = ref(null as any);
    const onSubmit = async () => {
      if (submiting.value) return;

      let res: any;
      try {
        await editorFormRef.value.validate();
        submiting.value = true;

        const data = {
          enterpriseInvitationInformationInfo: formData.value,
          departmentIds: formData.value.departmentIds,
          restrictConfNo: formData.value.restrictConfNo,
          vehicleId: formData.value.vehicleId ? [].concat(formData.value.vehicleId) : [],
        };

        res = await request.put(createApiUrl('/newlinkSass/invitation/invitation-create-or-update'), data);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      current.value = 1;
      detail.value = res.data;
    };

    const onCopyUrl = () => {
      // Copy with options
      const copyRes = copy(`${state.enterprise.value.enterpriseName} 邀请您使用能链企服 -- 加油更快捷，报销更方便 \n点击链接: ${detail.value.url}`);

      if (copyRes) {
        message.success('已复制');
      } else {
        message.error('复制失败');
      }
    };

    const QrCodeRef = ref<any>(null as any);
    const onDownloadQrcode = () => {
      const dataUrl = QrCodeRef.value.getDataURL();
      // 生成一个a元素
      const a = document.createElement('a');
      const event = new MouseEvent('click');
      a.download = 'QrCode.png';
      // 将生成的URL设置为a.href属性
      a.href = dataUrl;
      // 触发a的单击事件
      a.dispatchEvent(event);
    };

    watch(
      () => formData.value.enterpriseId,
      async val => {
        formData.value.departmentIds = undefined;
        formData.value.restrictConfNo = undefined;
        formData.value.vehicleId = [];
        !store.getters['User/isScheduleModel'] && autoState.loadAutoList(val);
        departmentState.loadDepartmentTree(val);
        await sceneState.loadSceneList(val);
        formData.value.restrictConfNo = sceneState.sceneList.value.reduce((pre, i: any) => (i.defaultFlag === 1 ? i.confNo : pre), undefined);
      },
    );

    onMounted(async () => {
      instance?.$sensors.track('es_saas_staff_batchinvite_popup_view', { page_name: '员工_批量邀请员工页_浏览' });
      !store.getters['User/isScheduleModel'] && autoState.loadAutoList();
      departmentState.loadDepartmentTree();
      await sceneState.loadSceneList();
      formData.value.restrictConfNo = sceneState.sceneList.value.reduce((pre, i: any) => (i.defaultFlag === 1 ? i.confNo : pre), undefined);
    });

    return {
      ...autoState,
      ...departmentState,
      localSceneList,

      labelCol: { span: 6 },
      wrapperCol: { span: 14, offset: 1 },
      validateOptions: [
        { label: '需要企业审核', value: 'NEED' },
        { label: '无需审核', value: 'NONEED' },
      ],
      limitOptions: [
        { label: '不限金额', value: 'INFINITE' },
        { label: '限制金额', value: 'FINITE' },
      ],
      current,
      detail,

      editorFormRef,
      formData,
      rules,
      onSubmit,

      onCopyUrl,

      QrCodeRef,
      onDownloadQrcode,
    };
  },
});
