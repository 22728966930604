import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "departmentDetailDrawer",
    width: "450",
    title: "查看邀请详情",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        class: "departmentDetailDrawer__content",
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "邀请名称",
            name: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inviteDetail.invitationName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "企业审核" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inviteDetail.examineState === 'NEED' ? '需要审核' : '无需审核'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "部门" }, {
            default: _withCtx(() => [
              (!_ctx.inviteDetail.invitationDepartmentDtos || !_ctx.inviteDetail.invitationDepartmentDtos.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "---"))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.inviteDetail.invitationDepartmentDtos, (item) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: item.departmentId
                    }, _toDisplayString(item.departmentNameWithParent), 1))
                  }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "车辆" }, {
            default: _withCtx(() => [
              (!_ctx.inviteDetail.invitationVehicleDtos || !_ctx.inviteDetail.invitationVehicleDtos.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "---"))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.inviteDetail.invitationVehicleDtos, (item) => {
                    return (_openBlock(), _createBlock(_component_a_tag, {
                      key: item.vehicleId
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.vehicleName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "加油制度" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inviteDetail.restrictConfTitle || '---'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible"]))
}