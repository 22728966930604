
import { ref, watch, computed, defineComponent, PropType, reactive } from 'vue';
import { message } from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment';

import InputNumber from '@/components/InputNumber/InputNumber.vue';
import { request } from '@/utils/request';
import { createApiUrl, toApiUnit, toLocalUnit, firstError, isValidateError } from '@/utils/utils';
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';

type RowDetail = {
    id: number, // 主键id
    templateId: number, // 模板id
    phone: string, // 员工手机号
    jobNumber: string // 员工工号
    departmentIdStr: string // 部门id集合
    departmentNameStr: string // 部门名称集合
    adjustmentAmount: number, // 本次调整金额-分
    refreshCycle: number, // 刷新周期  1-周  2-月
    inheritFlag: number, // 剩余额度是否计入累计额度：0-不继承  1-继承
    timelinessFlag: number, // 是否立即生效 0-下周起生效 1-立即生效
    activate: number, // 生效标识：0-关闭 1-开启
    deleteFlag: number, // 删除标识：1-未删除。2-已删除
    createTime: string, // 创建时间
    arName: string, // 员工姓名
    enterpriseId: number, // 企业id
    userCode: string, // 员工的userCode
    effectiveDate: number | null,
};

export default defineComponent({
  components: {
    InputNumber,
    QuestionCircleOutlined,
  },
  props: {
    rowDetail: { required: true, type: Object as PropType<RowDetail> },
  },
  emits: ['click', 'closeModal'],
  setup(props, ctx) {
    const formData = reactive<{
        adjustmentAmount: string | number,
        refreshCycle: number, 
        inheritFlag: boolean,
        activate: boolean,
        timelinessFlag: number,
        effectiveDate: number | null,
    }>({
      adjustmentAmount: props.rowDetail.adjustmentAmount.toString() || '',
      refreshCycle: props.rowDetail.refreshCycle || 2,
      inheritFlag: Boolean(!props.rowDetail.inheritFlag),
      activate: Boolean(props.rowDetail.activate),
      timelinessFlag: props.rowDetail.timelinessFlag,
      effectiveDate: props.rowDetail.effectiveDate || null,
    });
    const formRef = ref();
    const validatePass = async (rule: RuleObject, value: string) => {
      if (!Number(formData.adjustmentAmount) && formData.activate) {
        return Promise.reject('请输入正数！');
      }
      if (!/^[0-9]{1,6}(\.\d{0,2})?$/.test(formData.adjustmentAmount.toString()) && formData.activate) {
        return Promise.reject('请输入小于999999.99且保留两位小数的数值！');
      } 
      return Promise.resolve();
    };
    const rules = {
      adjustmentAmount: [{ validator: validatePass, trigger: 'change' }],
    };
    const dateList: number[] = reactive([]);
    for (let i = 1; i <= 25; i++) {
      dateList.push(i);
    }

    const timeStr = computed(() => {
      let str = '';
      if (formData.refreshCycle === 1) {
        str = moment().date((moment().date() - (moment().day() - 1)) + 7).format('YYYY.MM.DD');
      } else {
        str = moment().month(moment().month() + 1).date(1).format('YYYY.MM.DD');
      }
      return str;
    });

    const visibleSubmit = ref(false);
    const visibleDesc = ref('');

    watch(() => props.rowDetail, newValue => {
      const { adjustmentAmount = '', inheritFlag, refreshCycle = 2, timelinessFlag, activate } = newValue;
      formData.adjustmentAmount = adjustmentAmount.toString();
      formData.inheritFlag = Boolean(!inheritFlag);
      formData.activate = Boolean(activate);
      formData.refreshCycle = refreshCycle;
      formData.timelinessFlag = timelinessFlag;
    }, { deep: true });

    const closeModal = () => {
      formRef.value.resetFields();
    };

    const onCancel = () => {
      visibleSubmit.value = false;
      closeModal();
      ctx.emit('closeModal');
    };

    const onSubmit = async () => {
      if (!formData.adjustmentAmount) return message.error('请输入金额');
      
      try { 
        await formRef.value.validateFields();
        const res = await request.post(createApiUrl('/newlinkSass/quota/ar/updateBatchTemplateMember'), { 
          ...formData,
          memberId: props.rowDetail.id,
          inheritFlag: Number(!formData.inheritFlag),
          activate: Number(formData.activate),
          adjustmentAmount: formData.activate ? toApiUnit(+formData.adjustmentAmount) : 100,
        });
        message.success(res.msg as string);
        ctx.emit('closeModal', true);
      } catch (e: Error & any) {
        console.log(e);
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }

    };

    return {
      timeStr,
      formData,
      formRef,
      rules,
      dateList,

      closeModal,
      onCancel,
      onSubmit,

      visibleSubmit,
      visibleDesc,
    };
  },
});
