
import { defineComponent, reactive, ref, computed } from 'vue';
import { UserStoreIndexList } from '@/store/modules/user';
import ls from '@/utils/ls';
import { message } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { APP_ENV } from '@/utils/env';
import { AppStoreIndexList } from '@/store/modules/app';
import { v4 as uuidV4 } from 'uuid';
import { useState } from '@/store';
import { request } from '@/utils/request';

interface Response {
    code: number,
    data: string,
    msg: string
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: Response;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  props: {
    templateId: { type: String, default: '' },
  },
  emits: [
    'handleCancel',
  ],
  setup(props, ctx) {
    const state = useState();
    const quotaType = sessionStorage.getItem('quota_type');
    const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId')) || state.enterprise.value.enterpriseId;
    const uploadUrl = createApiUrl(quotaType === 'general' ? `/newlinkSass/excel/import/quota/normal/members?enterpriseId=${enterpriseId}&templateId=${props.templateId}` : `/newlinkSass/excel/import/quota/cycle/members?enterpriseId=${enterpriseId}&templateId=${props.templateId}`); // 上传的图片服务器地址
    const headers = reactive({
      'token-45qm2': ls.get(UserStoreIndexList.ACCESS_TOKEN),
      'app-type': 'NLESAAS',
      'app-version': APP_ENV.VUE_APP_VERSION,
      'device-id': ls.get(AppStoreIndexList.DEVICE_ID, uuidV4()),
      'device-type': 'SAAS',
      'device-version': APP_ENV.VUE_APP_VERSION,
      'nl-channel': 'web',
      rts: (new Date()).getTime(),
      enterpriseId: state.enterprise.value.enterpriseId,
    });
    const fileName = ref('');
    const file = ref('');
    const loading = ref(false);
    const fileList = ref([]);

    const beforeUpload = () => {
      headers.rts = (new Date()).getTime();
      return true;
    };
    // 下载模版
    const onDownload = async () => {
      const url = quotaType === 'general' ? 'https://nl-frontend-test-1305297355.cos.ap-beijing.myqcloud.com/payRevision/nl-saas/template/%E6%99%AE%E9%80%9A%E9%A2%9D%E5%BA%A6%E8%B0%83%E6%95%B4.xlsx' : 'https://nl-frontend-test-1305297355.cos.ap-beijing.myqcloud.com/payRevision/nl-saas/template/%E5%AE%9A%E6%9C%9F%E9%A2%9D%E5%BA%A6%E8%B0%83%E6%95%B4%20%284%29.xlsx';
      window.open(url, '_parent');
    };
    // 文件上传
    const handleChange = (info: FileInfo) => {
      headers.rts = (new Date()).getTime();
      loading.value = true;
      const { response = { code: 200, msg: '' }, status } = info.file;
      if (status === 'done') {
        loading.value = false;
        if (response.code !== 200) return message.error(response.msg);
        fileName.value = info.file.name || '';
        message.success('已上传成功～');
      }
    };
    const handleCancel = () => {
      ctx.emit('handleCancel');
    };
    const handleOk = () => {
      ctx.emit('handleCancel');
    };

    return {
      fileList,
      uploadUrl,
      headers,
      fileName,
      file,
      loading,
      handleChange,
      beforeUpload,
      handleCancel,
      handleOk,
      onDownload,
    };
  },
});
