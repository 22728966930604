
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';

import { useState } from '@/store';
import { request } from '@/utils/request';

export default defineComponent({
  props: {

  },
  emits: [
    'close',
    'handleOk',
  ],
  setup(props, ctx) {
    const state = useState();
    const formRef = ref();
    const formData = reactive<{ templateName: string }>({ templateName: '' });

    const handleCancel = () => {
      ctx.emit('close');
    };
    const handleOk = async () => {
      try {
        await formRef.value.validate();
        ctx.emit('handleOk', formData);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };

    return {
      formRef,
      formData,
      handleCancel,
      handleOk,
    };
  },
});
