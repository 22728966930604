
import { defineComponent, onMounted, ref, watch } from 'vue';

import { txcOpen } from '@/utils/txc';
import { useInvite } from '@/views/main/staff/invite/utils';

export default defineComponent({
  name: 'OverviewViewGuide',
  setup(props, ctx) {
    const inviteState = useInvite();

    const currentStep = ref(0);
    const isCollapse = ref(false);

    return {
      currentStep,
      isCollapse,
      txcOpen,

      onGotoInvite: inviteState.onGotoInvite,

      intrBg: [
        require('@/assets/dashboard/overview/tutorial-add-employee.png'),
        require('@/assets/dashboard/overview/tutorial-pay.png'),
        require('@/assets/dashboard/overview/tutorial-oil.png'),
      ],
    };
  },
});
