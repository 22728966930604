
import { defineComponent } from 'vue';
import { request } from '@/utils/request';
import { message } from 'ant-design-vue';
import { createApiUrl, sldPageTitle } from '@/utils/utils';


export default defineComponent({
  name: 'Welfare',
  setup(props, ctx) {

    const loadPage = async () => {
      try {
        const res = await request.post(createApiUrl('/newlinkSass/tradeMao/getTradeMaoUrl'), {});
        if (res.code === 200) window.open(res.data);
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    loadPage();

    return {
      sldPageTitle,
    };
  },
});
