import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

// 获取验证图片  以及token
export function reqGet() {
  return request.get(createApiUrl('/newlinkSass/captcha/get'));
}

// 滑动或者点选验证
export function reqCheck(data: Record<string, any>) {
  return request.post(createApiUrl('/newlinkSass/captcha/check'), data);
}
