import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { parseNumberOrZero } from '@/utils/illuminate';
import { useStore } from 'vuex';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';

/* eslint-disable camelcase */
export type SceneRecordType = {
  enterpriseId: number;
  confNo: number;
  default_flag: number;
  num: number;
  title: string;
}

export enum FrequencyType {
  NONE,
  DAY,
  WEEK,
  MONTH,
}

export const frequencyTypeMap: Record<any, string> = {
  [FrequencyType.NONE]: '不限',
  [FrequencyType.DAY]: '每日',
  [FrequencyType.WEEK]: '每周',
  [FrequencyType.MONTH]: '每月',
};

export type stepSupplementItemType = {
  key: number | string,
  startKm: number | string,
  endKm: number | string,
  supplementLimitAmount: number | string
}

export type SceneType = {
  defaultFlag: number;
  confNo?: number; //	制度编号
  dayLimitAmount: string; //	单日限制金额分
  dayLimitAmountStatus: boolean; //	单日限额是否生效
  frequency: number; //	加油频次 0-不限制1-每日2-每周3-每月
  frequencyNum: number | string; // 次数
  singleLimitAmount: string; //	单笔限制金额分
  singleLimitAmountStatus: boolean; //	单笔限额是否生效
  title: string; //	制度名称
  enterpriseId: number | undefined; // 企业 Id
  enterpriseName: string | undefined;

  // 私车公用油补管理
  oilSupplementStatus: number; // 油补管理状态：0-关闭(默认)，1-开启
  perKilometerAmount: number | ''; // 油补标准：每公里费用（分）
  radius: number | ''; // 打卡半径（米）
  updateAddressYn: number; // 是否允许修改地址 1-是 0-否
  supplementPassPointYn: number; // 是否允许补充途径点 1-是 0-否
  // passPointConf: number; // 途经点位置：0-只允许微调，1-自定义位置
  supplementLimitAmount: number | ''; // 油补上限（分）
  approveType: number | null; // 审批方式 1-人工加自动 2-自动审批 3-人工审批
  customRuleYn: number; // 是否有自定义规则 1-是 0-否
  customTitle: string; // 自定义规则标题
  customContent: string; // 自定义标题内容
  calculationMethod: number; // 油补方式
  stepSupplementLimits: stepSupplementItemType[],
  oilProhibitFlag: number,
  qyNos: Array<any>,
  qyNames: Array<any>,
  cyNos: Array<any>,
  cyNames: Array<any>,
  trqNos: Array<any>,
  trqNames: Array<any>,
  prohibitOilNos: string,
  prohibitOilNames: string,
  allowDeclaredMile: boolean, // 是否允许申报行驶里程
  mustUploadProof?: boolean, // 是否强制上传举证图片
  allowSelfApprovalFlag?: boolean,
  supplementLimitType?: number, // 油补上限类型
  quota?: number, // 油补上限
  totalQuota?: number, //   // 油补模式企业 报销上限管理,
  expresswayQuota?: number, // 高速费
  oilSubsidyOfflineFlag?: number, // 是否允许线下报销
};

export function useScene() {
  const store = useStore();
  const state = useState();
  // = ---------------------------- = 加载制度列表 作为表单 = ---------------------------- =
  const sceneList = ref([]);
  const loadSceneList = async (enterpriseId?: number) => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/staff/restrict/conf/list'), {
        params: { enterpriseId },
      });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    //  confNo：制度编码
    //  defaultFlag：默认制度 1
    //  num：使用员工
    //  title：标题
    sceneList.value = res.data;
  };

  const defaultStepSupplementLimits: stepSupplementItemType[] = [
    { key: Date.now(), startKm: 0, endKm: '', supplementLimitAmount: '' },
    { key: Date.now() + 1, startKm: '', endKm: '', supplementLimitAmount: '' },
  ];

  const getDefaultRecordData = (): SceneType => ({
    confNo: 0,
    defaultFlag: 0,
    title: '',
    enterpriseId: state.enterprise?.value.enterpriseId,
    dayLimitAmount: '',
    dayLimitAmountStatus: false,
    frequency: 0,
    frequencyNum: '',
    singleLimitAmount: '',
    singleLimitAmountStatus: false,

    oilSupplementStatus: 0,
    perKilometerAmount: '',
    radius: 500,
    updateAddressYn: 1, // 修改地址默认选择允许
    supplementPassPointYn: 1,
    // passPointConf: 0,
    supplementLimitAmount: '',
    approveType: 1,
    customRuleYn: 0,
    customTitle: '',
    customContent: '',
    calculationMethod: 0,
    stepSupplementLimits: defaultStepSupplementLimits,
    oilProhibitFlag: 0,
    qyNos: [],
    qyNames: [],
    cyNos: [],
    cyNames: [],
    trqNos: [],
    trqNames: [],
    prohibitOilNos: '',
    prohibitOilNames: '',
    allowDeclaredMile: false,
    mustUploadProof: true,
    enterpriseName: '',
    allowSelfApprovalFlag: true,
    supplementLimitType: 1,
    oilSubsidyOfflineFlag: 0,
  });

  // = ---------------------------- = 加载制度详情 = ---------------------------- =
  const sceneData = reactive({
    ...getDefaultRecordData(),
  });

  const loadSceneData = async (no: string, enterpriseId?: string) => {
    let res: any[];
    try {
      const promiseList = [request.get(createApiUrl('/newlinkSass/staff/restrict/get/detail'), {
        params: { confNo: no, enterpriseId },
      })];
      if (store.getters['User/isScheduleModel']) {
        promiseList.push(request.get(
          createApiUrl('/newlinkSass/staff/restrict/get/oil/supplement/limit'),
          { params: { confNo: no, enterpriseId } },
        ));
      }
      res = await Promise.all(promiseList);
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    const { stepSupplementLimits } = res[0].data;
    Object.assign(sceneData, {
      ...res[0].data,
      dayLimitAmountStatus: !!res[0].data.dayLimitAmountStatus,
      singleLimitAmountStatus: !!res[0].data.singleLimitAmountStatus,
      dayLimitAmount: toLocalUnit(parseNumberOrZero(res[0].data.dayLimitAmount || 0)),
      singleLimitAmount: toLocalUnit(parseNumberOrZero(res[0].data.singleLimitAmount || 0)),
      supplementLimitType: (res[1] && res[1].data) ? res[1].data.supplementLimitType : 1,
      quota: (res[1] && res[1].data) ? toLocalUnit(res[1].data.quota || 0) : '0',
      totalQuota: (res[1] && res[1].data) ? toLocalUnit(res[1].data.totalQuota || 0) : '0',
      expresswayQuota: (res[1] && res[1].data) ? toLocalUnit(res[1].data.expresswayQuota || 0) : '0',
      perKilometerAmount: toLocalUnit(res[0].data.perKilometerAmount || 0), // 油补标准：每公里费用（分）
      supplementLimitAmount: toLocalUnit(res[0].data.supplementLimitAmount || 0), // 油补上限（分）
      stepSupplementLimits: Array.isArray(stepSupplementLimits) && stepSupplementLimits.length ? stepSupplementLimits.map((item: stepSupplementItemType) => {
        item.supplementLimitAmount = toLocalUnit(parseNumberOrZero(item.supplementLimitAmount || 0));
        return item;
      }) : defaultStepSupplementLimits,
    });
  };

  return {
    getDefaultRecordData,
    sceneList,
    loadSceneList,

    sceneData,
    loadSceneData,
  };
}
