import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fl-row departmentDelModal__infoRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "departmentDelModal deleteConfirm noneFooterBorder",
    width: "432px",
    "ok-text": "确认",
    "ok-button-props": { prefixCls: 'ant-btn' },
    onOk: _ctx.onOK
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_app_icon, {
                  type: "question-circle-filled",
                  class: "text-warning"
                }),
                (_ctx.departmentDetail.num)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" 该部门下仍有员工，是否继续删除" + _toDisplayString(_ctx.departmentDetail.departmentName) + "？ ", 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" 确认删除 " + _toDisplayString(_ctx.departmentDetail.departmentName) + "? ", 1)
                    ], 64))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "onOk"]))
}