
import { defineComponent, reactive, ref, computed } from 'vue';
import { useUtils } from '@/core/utils';

import { useInvite } from './utils';

export default defineComponent({
  name: 'InviteDetailDrawer',
  setup(props, ctx) {
    const utils = useUtils();
    const inviteState = useInvite();
    const visible = ref(false);

    const record = ref<any>({ id: 0 });
    const detail = ref({});

    const view = (item: any) => {
      visible.value = true;
      record.value = item;
      inviteState.loadInviteDetail(item.id, item.enterpriseId);
    };

    return {
      ...utils,
      ...inviteState,

      labelCol: { span: 6 },
      wrapperCol: { span: 14, offset: 1 },

      visible,
      detail,

      view,
    };
  },
});
