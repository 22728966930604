
import { defineComponent, computed, ref, reactive, watch, getCurrentInstance, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { noEmptyProp } from '@/utils/illuminate';
import { useStore } from 'vuex';

import type { TableColumn, TableOptions } from '@/components/Table';
import { useState } from '@/store';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { formatDate } from '@/core/filters';

const stateMap: Record<number, string> = {
  1: 'WAIT',
  2: 'SUCCESS',
  3: 'FAIL',
};

export default defineComponent({
  name: 'InviteStep1View',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const state = useState();
    const current = ref(1);
    const filterParams = reactive<{
      userRelId: string | undefined;
      invitationName: string;
      enterpriseId: number | string,
    }>({
      userRelId: undefined,
      invitationName: '',
      enterpriseId: '',
    });
    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);

    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '员工姓名', dataIndex: 'userName' },
        { title: '手机号码', dataIndex: 'phone' },
        { title: '公司', dataIndex: 'enterpriseName' },
        { title: '邀请方式', dataIndex: 'channelType', customRender: ({ text }) => (text === 1 ? '手机' : text === 2 ? '邮件' : text === 3 ? '链接' : '') },
        { title: '信息', dataIndex: 'channel' },
        { title: '提交时间', dataIndex: 'applyDateTime', customRender: ({ text }) => (text ? formatDate(text, 'YYYY-MM-DD HH:mm:ss') : '---') },
      ];

      if (current.value > 1) {
        basic.push({ title: '操作人', dataIndex: 'operationName' });
        basic.push({ title: '操作时间', dataIndex: 'invitationDateTime', customRender: ({ text }) => (text ? formatDate(text, 'YYYY-MM-DD HH:mm:ss') : '---') });
      }

      if (current.value < 2) {
        basic.push({ title: '操作', dataIndex: 'action', width: '120px', slots: { customRender: 'action' }, fixed: 'right' });
      }

      return basic;
    });

    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = selectedRows;
    };

    const tableOptions = computed<TableOptions>(() => ({
      alert: { show: false, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          props: { name: 'check-line' },
        }),
      },
    }));

    const badgeTotal = ref(0);
    const tableRef = ref<any>(null as any);
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/invitation/invitation-find-user-list'), {
        params: {
          ...parameter,
          ...noEmptyProp(filterParams),
          state: stateMap[current.value],
        },
        noEnterpriseId: true,
      })
        .then((res: any) => {
          if (current.value === 1) {
            store.commit('User/setInviteUserNum', res.data.total);
            badgeTotal.value = res.data.total > 99 ? 99 : res.data.total;
          }
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    watch(() => filterParams.userRelId, onRefreshTable);
    watch(current, onRefreshTable);

    const getUserList = async (val: string) => {
      const params: any = { nameOrPhone: val, state: stateMap[current.value] };

      if (filterParams.enterpriseId) {
        params.enterpriseId = filterParams.enterpriseId;
      }

      const res = await request.get<Record<string, string>[]>(createApiUrl('/newlinkSass/invitation/find-invitation-user-list-by-name-or-phone'), { params, noEnterpriseId: true });
      return { ...res, data: res.data.map(item => ({ ...item, value: item.userRelId })) };
    };

    const submitResult = async (ids: number[], state: string) => {
      try {
        await request.put(createApiUrl('/newlinkSass/invitation/invitation-update-user-invitation-rel-for-list'), { ids, state });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success(`${state === 'SUCCESS' ? '确认' : '拒绝'} 加入操作完成.`);
      onRefreshTable();
    };

    const mutiSubmit = (state: string) => {
      if (!tableSelectedRows.value.length) return;

      submitResult(
        tableSelectedRows.value.map((item: any) => item.id),
        state,
      );
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_staff_verify_page_view', { page_name: '员工_员工加入审核页_浏览' });
    });

    return {
      badgeTotal,

      current,
      filterParams,
      tableSelectedRowKeys,
      tableSelectedRows,

      tableRef,
      tableColumns,
      tableOptions,

      loadTableData,
      onRefreshTable,

      getUserList,
      submitResult,
      mutiSubmit,
    };
  },
});
