import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
    class: "departmentActionDrawer",
    title: _ctx.title,
    width: "500px",
    "confirm-loading": _ctx.dataLoading,
    onOk: _ctx.onSubmit,
    onCancel: _ctx.resetFields
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "editorFormRef",
        model: _ctx.formData,
        rules: _ctx.rules,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_section, {
            "allow-edit": "",
            editing: _ctx.action !== 'view',
            onEdit: _ctx.onSwitchEdit,
            onCancel: _ctx.onSwitchCancel,
            onSubmit: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "公司",
                name: "enterpriseId"
              }, {
                default: _withCtx(() => [
                  (_ctx.action !== 'create')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formData.enterpriseName), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_app_enterprise_input, {
                      value: _ctx.formData.enterpriseId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.enterpriseId) = $event)),
                      style: {"width":"100%"},
                      "disable-in-open": ""
                    }, null, 8, ["value"])
                  ], 512), [
                    [_vShow, _ctx.action === 'create']
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "部门名称",
                name: "departmentName"
              }, {
                default: _withCtx(() => [
                  (_ctx.action === 'view')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.formData.departmentName), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createVNode(_component_a_input, {
                    value: _ctx.formData.departmentName,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.departmentName) = $event)),
                    "allow-clear": ""
                  }, null, 8, ["value"]), [
                    [_vShow, _ctx.action !== 'view']
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "上级部门",
                name: "parentDepartmentId"
              }, {
                default: _withCtx(() => [
                  (_ctx.action === 'view')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.departmentDetail.parentDepartmentName || '无'), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_a_tree_select, {
                      value: _ctx.formData.parentDepartmentId,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.parentDepartmentId) = $event)),
                      style: {"width":"100%"},
                      "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                      "tree-data": _ctx.departmentList,
                      placeholder: "请选择部门"
                    }, {
                      title: _withCtx(({ value }) => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 1
                    }, 8, ["value", "tree-data"])
                  ], 512), [
                    [_vShow, _ctx.action !== 'view']
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "部门主管",
                name: "managerUserCode"
              }, {
                default: _withCtx(() => [
                  (_ctx.action === 'view')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formData.managerUserName || '无'), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("div", null, [
                    _createVNode(_component_a_tree_select, {
                      value: _ctx.formData.managerUserCode,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.managerUserCode) = $event)),
                      style: {"width":"100%"},
                      "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                      "tree-data": _ctx.userCodeList,
                      placeholder: "请选择部门主管"
                    }, {
                      title: _withCtx(({ value }) => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 1
                    }, 8, ["value", "tree-data"])
                  ], 512), [
                    [_vShow, _ctx.action !== 'view']
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["editing", "onEdit", "onCancel", "onSubmit"])
        ]),
        _: 1
      }, 8, ["model", "rules", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible", "title", "confirm-loading", "onOk", "onCancel"]))
}