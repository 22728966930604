
import { ref, reactive, watch, createVNode, computed, defineComponent, PropType, onMounted } from 'vue';
import { formatCny } from '@/utils/illuminate';
import { message, Modal } from 'ant-design-vue';
import { QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment';

import InputNumber from '@/components/InputNumber/InputNumber.vue';
import { RowDetail } from '@/components/QuotaAdjustment/utils';
import { request } from '@/utils/request';
import { createApiUrl, toApiUnit, toLocalUnit } from '@/utils/utils';

/* eslint-disable vue/no-mutating-props */
export default defineComponent({
  components: {
    InputNumber,
    QuestionCircleOutlined,
  },
  props: {
    rowDetail: { required: true, type: Object as PropType<RowDetail> },
  },
  emits: ['click', 'closeModal'],
  setup(props, ctx) {
    let amountBalance = 0;
    if (props.rowDetail.quotaAmount || props.rowDetail.periodRuleBalance) {
      amountBalance = props.rowDetail.quotaAmount || props.rowDetail.periodRuleBalance;
    }
    const numValue = ref(props.rowDetail.activate ? toLocalUnit(amountBalance).toString() : '');
    const checked = ref(props.rowDetail.activate ? Boolean(!props.rowDetail.inheritFlag) : true); // false 继承， true 不继承
    const signValue = ref(props.rowDetail.activate ? props.rowDetail.refreshCycle : 2);
    const effecteTime = ref(props.rowDetail.activate ? props.rowDetail.timelinessFlag : 1);
    const effectiveDate = ref(props.rowDetail.activate ? props.rowDetail.effectiveDate : null);
    const showEffectiveDate = ref(props.rowDetail.activate ? props.rowDetail.effectiveDate : null);
    const showDate = ref(true);

    const dateList: number[] = reactive([]);
    for (let i = 1; i <= 25; i++) {
      dateList.push(i);
    }

    const operateType = ref('detail');

    const timeStr = computed(() => {
      let str = '';
      if (signValue.value === 1) {
        str = moment().date((moment().date() - (moment().day() - 1)) + 7).format('YYYY.MM.DD');
      } else {
        str = moment().month(moment().month() + 1).date(1).format('YYYY.MM.DD');
      }
      return str;
    });

    const visibleSubmit = ref(false);
    const visibleDesc = ref('');

    watch(() => props.rowDetail, newValue => {
      let amountBalance = 0;
      if (newValue!.quotaAmount || newValue!.periodRuleBalance) {
        amountBalance = newValue!.quotaAmount || newValue!.periodRuleBalance;
      }
      numValue.value = newValue!.activate ? toLocalUnit(amountBalance).toString() : ''; // props.rowDetail.quotaAmount
      checked.value = newValue!.activate ? Boolean(!newValue!.inheritFlag) : true; // false 继承， true 不继承
      signValue.value = newValue!.activate ? newValue!.refreshCycle : 2;
      effecteTime.value = newValue!.activate ? newValue!.timelinessFlag : 1;
      effectiveDate.value = newValue!.activate ? newValue!.effectiveDate : null;
      showEffectiveDate.value = newValue!.activate ? newValue!.effectiveDate : null;

      // eslint-disable-next-line no-use-before-define
      changeEffectDate(effectiveDate.value);
    });

    watch(numValue, (newValue, oldValue) => {
      if (!newValue || (newValue.length && newValue.indexOf('.') === newValue.length - 1)) return;
      const moneyReg = /(^[1-9]([0-9]{0,5})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]{1,6}\.[0-9]{1,2}?$)/;
      if (!moneyReg.test(newValue)) numValue.value = oldValue;
    });

    const params = computed(() => ({
      inheritFlag: Number(!checked.value), // 剩余额度是否计入累计额度：0-不继承 1-继承
      quotaAmount: toApiUnit(+numValue.value), // 定期金额-分
      refreshCycle: signValue.value, // 刷新周期 1-周 2-月
      timelinessFlag: effecteTime.value, // 是否立即生效 0-下周起生效 1-立即生效
      effectiveDate: effectiveDate.value, // 自定义每月几号生效
    }));

    const getTime = (yy: number, mm: number, dd: number) => {
      const d = new Date(yy, mm, dd);
      const nowMonth = d.getMonth() + 1;
      const nowDate = d.getDate();
      return `${nowMonth < 10 ? `0${nowMonth}` : nowMonth}.${nowDate < 10 ? `0${nowDate}` : nowDate}`;
    };

    const tableList = computed(() => {
      let d = new Date();
      let i = 6;
      const data = [];
      while (i > 0) {
        let nowYear = d.getFullYear();
        let nowMonth = d.getMonth() + 1;
        if (signValue.value === 1) { // 每周
          const nowWeek = d.getDay();
          nowYear = d.getFullYear();
          nowMonth = d.getMonth() + 1;
          let nowDate = d.getDate() - (nowWeek - 1);
          const week = moment([nowYear, nowMonth, nowDate]).week();
          data.push(`${week}周 (${getTime(nowYear, nowMonth - 1, nowDate)}-${getTime(nowYear, nowMonth - 1, nowDate + 6)})`);
          nowDate += 7;
          d = new Date(nowYear, nowMonth - 1, nowDate);
        } else { // 每月
          data.push(`${nowMonth === 1 ? `${nowYear}年` : ''}${nowMonth}月`);
          nowMonth += 1;
          d = new Date(nowYear, nowMonth - 1);
        }
        i--;
      }
      return data;
    });

    const editBtn = () => {
      operateType.value = 'edit';
    };

    const closeModal = () => {
      numValue.value = '';
      checked.value = true;
      signValue.value = 2;
      effecteTime.value = 1;
      operateType.value = 'detail';
    };

    const deleteBtn = () => {
      const nameStr = props.rowDetail.modalType === 'car' ? `（${props.rowDetail.licensePlate || props.rowDetail.vin}）`
        : props.rowDetail.modalType === 'person' ? `（${props.rowDetail.userName}）`
          : props.rowDetail.modalType === 'department' ? `（${props.rowDetail.departmentName}）` : '';
      Modal.confirm({
        title: `确认删除 ${nameStr} 的定期额度 ？`,
        icon: createVNode(QuestionCircleFilled),
        async onOk() {
          try {
            let res;
            if (props.rowDetail.modalType === 'car') {
              res = await request.put(createApiUrl('/newlinkSass/quota/vr/editQuotaRuleInfo'), {
                activate: 0, // 车辆定期额度开启标识：0-关闭 默认1-开启
                ...params.value,
                timelinessFlag: 1,
                vehicleNo: props.rowDetail.vehicleNo,
              });
            } else if (props.rowDetail.modalType === 'person') {
              res = await request.put(createApiUrl('/newlinkSass/quota/ar/editStaffQuotaRule'), {
                activate: 0,
                ...params.value,
                timelinessFlag: 1,
                userCode: props.rowDetail.userCode, // 员工编号
              });
            } else if (props.rowDetail.modalType === 'department') {
              res = await request.put(createApiUrl('/newlinkSass/quota/dr/editDepartmentQuotaRule'), {
                activate: 0,
                ...params.value,
                timelinessFlag: 1,
                departmentId: props.rowDetail.id, // 部门编号
              });
            }

            if (res && res.code === 200) {
              props.rowDetail.activate = 0;
              props.rowDetail.lastActivate = 0;
              operateType.value = 'edit';
              message.success('删除成功');
            }
          } catch (e: Error & any) {
            message.error(e.message);
          } finally {
            closeModal();
          }
        },
        onCancel() {
          //
        },
      });
    };

    const onCancel = () => {
      visibleSubmit.value = false;
      if (!props.rowDetail.activate) return ctx.emit('closeModal', true);

      const amount = props.rowDetail.quotaAmount || props.rowDetail.periodRuleBalance;
      numValue.value = props.rowDetail.activate ? formatCny((amount / 100).toString()) : ''; // props.rowDetail.quotaAmount
      checked.value = props.rowDetail.activate ? Boolean(!props.rowDetail.inheritFlag) : true; // false 继承， true 不继承
      signValue.value = props.rowDetail.activate ? props.rowDetail.refreshCycle : 2;
      effecteTime.value = props.rowDetail.activate ? props.rowDetail.timelinessFlag : 1;

      operateType.value = 'detail';
    };

    const onSubmitModal = async () => {
      try {
        let res;
        if (props.rowDetail.modalType === 'car') {
          res = await request.put(createApiUrl('/newlinkSass/quota/vr/editQuotaRuleInfo'), {
            activate: 1, // 车辆定期额度开启标识：0-关闭 默认1-开启
            ...params.value,
            vehicleNo: props.rowDetail.vehicleNo,
          });
        } else if (props.rowDetail.modalType === 'person') {
          res = await request.put(createApiUrl('/newlinkSass/quota/ar/editStaffQuotaRule'), {
            activate: 1,
            ...params.value,
            userCode: props.rowDetail.userCode, // 员工编号
          });
        } else if (props.rowDetail.modalType === 'department') {
          res = await request.put(createApiUrl('/newlinkSass/quota/dr/editDepartmentQuotaRule'), {
            activate: 1,
            ...params.value,
            departmentId: props.rowDetail.id, // 部门编号
          });
        }
        if (res && res.code === 200) {
          message.success('操作成功');
          visibleSubmit.value = false;
          props.rowDetail.activate = 1;
          operateType.value = 'detail';
        }
      } catch (e: Error & any) {
        message.error(e.message);
      } finally {
        // props.rowDetail.activate = 1;
        props.rowDetail.quotaAmount = Number((Number(numValue.value) * 100).toFixed());
        props.rowDetail.inheritFlag = Number(!checked.value);
        props.rowDetail.refreshCycle = signValue.value;
        props.rowDetail.timelinessFlag = effecteTime.value;
        // operateType.value = 'detail';
      }
    };

    const onSubmit = async () => {
      if (!numValue.value) return message.error('请输入金额');
      if (effecteTime.value === 2 && !effectiveDate.value) return message.error('请选择生效日期');
      showEffectiveDate.value = effectiveDate.value;

      // 立即生效
      if (effecteTime.value === 1) {
        try {
          let getQuotaAmount;
          const paramsPublic = {
            quotaAmount: (Number(numValue.value) * 100).toFixed(),
            inheritFlag: Number(!checked.value), // 剩余额度是否计入累计额度：0-不继承 1-继承
          };
          if (props.rowDetail.modalType === 'car') {
            getQuotaAmount = await request.get(createApiUrl('/newlinkSass/quota/vr/checkInputVehicleQuotaAmount'), {
              params: {
                ...paramsPublic,
                vehicleNo: props.rowDetail.vehicleNo, // 车辆编号
              },
            });
          } else if (props.rowDetail.modalType === 'person') {
            getQuotaAmount = await request.get(createApiUrl('/newlinkSass/quota/ar/checkInputStaffQuotaAmount'), {
              params: {
                ...paramsPublic,
                userCode: props.rowDetail.userCode, // 员工编号
              },
            });
          } else if (props.rowDetail.modalType === 'department') {
            getQuotaAmount = await request.get(createApiUrl('/newlinkSass/quota/dr/checkInputDepartmentQuotaAmount'), {
              params: {
                ...paramsPublic,
                departmentId: props.rowDetail.id, // 部门编号
              },
            });
          }
          if (getQuotaAmount) {
            if (getQuotaAmount.code === 200) {
              if (!getQuotaAmount.data.pass) {
                visibleSubmit.value = true;
                visibleDesc.value = getQuotaAmount.data.msg;
                return;
              }
            } else {
              message.error(getQuotaAmount.message);
              return;
            }
          } else {
            return;
          }
        } catch (e: Error & any) {
          message.error(e.message);
          return;
        }
      }

      onSubmitModal();
    };

    // 判断自定义生效日期是否在当前日期之后
    function changeEffectDate(value:number|null|undefined):void {
      if (effecteTime.value !== 2) {
        showDate.value = true;
        return;
      }
      if (value) {
        const day = new Date().getDate();
        showDate.value = value > day;
      } else {
        showDate.value = false;
      }
    }

    // 修改生效时间
    function changeEffectTime(e:any):void {
      const target:number = e.target.value;
      if (target === 2) {
        changeEffectDate(effectiveDate.value);
      } else {
        showDate.value = true;
        effectiveDate.value = 1;
      }
    }

    onMounted(() => {
      changeEffectDate(effectiveDate.value);
    });

    return {
      operateType,
      timeStr,
      signValue,
      checked,
      effecteTime,
      numValue,
      dateList,
      showDate,
      showEffectiveDate,

      closeModal,
      deleteBtn,
      editBtn,
      tableList,
      onCancel,
      onSubmit,
      changeEffectDate,
      changeEffectTime,

      visibleSubmit,
      visibleDesc,
      onSubmitModal,
      effectiveDate,
    };
  },
});
