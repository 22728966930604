
import { defineComponent, computed, reactive, ref, watch, onMounted, getCurrentInstance } from 'vue';
import { message } from 'ant-design-vue';

import { emit } from '@/utils/eventBus';
import { debounce } from 'lodash';
import { useStore } from 'vuex';

import { settleTree, formatWithIntl } from '@/utils/illuminate';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { EVENT_LIST } from '@/utils/constants';
import { request } from '@/utils/request';
import { useCurrentEnterpriseHook } from '@/store';
import { EnterpriseItem } from '@/views/parts/subsidiary';

import QuotaAdjustment from '@/components/QuotaAdjustment/modal.vue';
import ActionDrawer from './ActionDrawer.vue';
import DelModal from './DelModal.vue';
import DepartmentMenuNew from '../parts/DepartmentMenuNew.vue';


export default defineComponent({
  name: 'DepartmentListView',
  components: {
    DepartmentMenuNew,
    ActionDrawer,
    DelModal,
    QuotaAdjustment,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    useCurrentEnterpriseHook();
    const store = useStore();
    const authority = ref(store.state.User.authority);

    const filterParams = reactive<{
      departmentId: number | undefined;
      searchDepartmentId: number | undefined;
    }>({
      departmentId: undefined,
      searchDepartmentId: undefined,
    });

    const tableColumns: TableColumn[] = [
      { title: '部门名称', dataIndex: 'departmentName' },
      { title: '部门主管', dataIndex: 'managerUserName' },
      { title: '部门人数', dataIndex: 'num' },
      { title: '部门可用额度', slots: { customRender: 'available' } },
      { title: '部门定期额度', dataIndex: 'periodRuleBalance', customRender: ({ text }) => formatWithIntl(text / 100) },
      { title: '可分配额度', slots: { customRender: 'distribution' } },
      { title: '剩余额度', dataIndex: 'surplusBalance', customRender: ({ text }) => formatWithIntl(text / 100) },
      { title: '额度管理', slots: { customRender: 'switch' } },
      { title: '操作', dataIndex: 'action', width: '180px', slots: { customRender: 'action' }, fixed: 'right' },
    ];
    const tableRef = ref<any>(null);
    // 加载数据方法
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/department/find-enterprise-department-list'), {
        params: {
          departmentId: filterParams.departmentId || filterParams.searchDepartmentId,
          ...parameter,
        },
      })
        .then((res: any) => {
          res.data.list.forEach((e: any) => {
            e.availableAmount = e.normalBalance + e.periodRuleBalance;
            e.isQuota = e.quotaSupported === 1;
          });
          resolve(res.data);
        }).catch((e: any) => {
          message.error(`部门列表请求错误: ${e.message || ''}`);
        });
    });

    const autoCompleteList = ref<any[]>([]);
    const getAutoCompleteList = async (val: string) => {
      if (!val) return;

      if (/[\u{10000}-\u{1FBFF}]/u.test(val)) {
        return message.error('部门检索不支持 Emoji… 特殊字符');
      }

      let resData: any[];
      try {
        ({ data: resData } = await request.get(createApiUrl('/newlinkSass/department/find-department-by-name'), {
          params: { departmentName: val },
        }));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      autoCompleteList.value = resData.map(i => ({ value: i.id, key: i.id, label: i.departmentName }));
    };

    const debouncedAutoComplete = debounce((val: string) => getAutoCompleteList(val), 300, { maxWait: 3000 });

    const onRefreshTable = () => {
      tableRef.value.refresh();
    };

    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };

    /* = -------------------------------------------------- = 部门菜单树 = -------------------------------------------------- = */

    const departmentMenuRef = ref<any>(null);

    const onChangeDepartment = (id: number) => {
      if (filterParams.departmentId === id) return;
      filterParams.departmentId = id <= 0 ? undefined : id;
      if (filterParams.departmentId) filterParams.searchDepartmentId = undefined;
      onRefreshTable();
    };

    watch(
      () => filterParams.searchDepartmentId,
      val => {
        if (val) {
          departmentMenuRef.value.resetDepartment();
        } else {
          onRefreshTable();
        }
      },
    );

    const onChangeCompany = (item: EnterpriseItem) => {
      store.commit('User/setCurrentEnterprise', item);
      store.commit('User/setCurrentEnterpriseId', item.enterpriseId);
      filterParams.departmentId = undefined;
      onRefreshTable();
      loadEnterpriseDepartment();
    };
    /* = -------------------------------------------------- = 部门额度 = -------------------------------------------------- = */
    const quotaAdjustRef = ref<any>(null);
    const onQuotaAdjust = (record: any) => {
      quotaAdjustRef.value.showModal({ type: 'department', val: record });
    };
    const quotaSupportedChange = async (checked: boolean, record: any) => {
      record.loading = true;
      try {
        await request.post(createApiUrl('/newlinkSass/department/openOrCloseQuota'), { departmentId: record.id, quotaSupported: checked ? 1 : 0 });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      // onRefreshTable();
      record.loading = false;
      record.quotaSupported = checked ? 1 : 0;
    };
    /* = -------------------------------------------------- = 创建/查看/编辑/删除 = -------------------------------------------------- = */
    const actionDrawerRef = ref<any>(null);
    const doWith = (action: string, id: number) => {
      actionDrawerRef.value.doWith(action, id);
    };
    const onActionComplete = () => {
      onRefreshTable();
      loadEnterpriseDepartment();
    };

    const onEditItem = (record: any) => {
      actionDrawerRef.value.edit(record);
    };

    const delRef = ref<any>(null);
    const onDelItem = async (record: any) => {
      delRef.value.del(record);
    };

    const onDelComplete = () => {
      departmentMenuRef.value.resetDepartment();
      emit(EVENT_LIST.DEPARTMENT_CHANGE);
      onRefreshTable();
      loadEnterpriseDepartment();
    };

    onMounted(() => {
      loadEnterpriseDepartment();
      instance?.$sensors.track('es_saas_organisation_page_view', { page_name: '组织架构页_浏览' });
    });

    return {
      authority,

      filterParams,
      tableColumns,

      tableRef,
      loadTableData,
      onRefreshTable,
      departmentList,

      // 部门相关逻辑
      departmentMenuRef,
      onDelComplete,
      onChangeDepartment,
      onChangeCompany,
      autoCompleteList,
      debouncedAutoComplete,

      actionDrawerRef,
      doWith,
      onActionComplete,

      onEditItem,

      delRef,
      onDelItem,

      // 部门额度
      quotaAdjustRef,
      onQuotaAdjust,
      quotaSupportedChange,
      sldPageTitle,
    };
  },
});
