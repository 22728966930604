import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17a87f79"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_member_title = _resolveComponent("app-member-title")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer" }, {
    title: _withCtx(() => [
      _createVNode(_component_app_member_title, { routes: _ctx.routes }, null, 8, ["routes"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_form, {
          class: "staffView__filterForm",
          model: _ctx.filterParams,
          layout: 'inline'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              name: "templateName",
              label: "模版名称"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.filterParams.templateId,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.templateId) = $event)),
                  style: {"width":"200px"},
                  placeholder: "请输入模版名称",
                  "show-search": "",
                  "allow-clear": "",
                  "default-active-first-option": false,
                  "show-arrow": false,
                  "filter-option": false,
                  "not-found-content": null,
                  onSearch: _ctx.handleSearch
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateList, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(`${item.templateName}(${item.id})`), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "onSearch"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  class: "fl-right",
                  type: "primary",
                  onClick: _ctx.onRefreshTable
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          size: "default",
          "row-key": "userCode",
          columns: _ctx.tableColumns,
          data: _ctx.loadTableData,
          "show-pagination": true
        }, {
          state: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(record.state == 1 ? '正在执行' : '执行完成'), 1)
          ]),
          action: _withCtx(({ record }) => [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _withModifiers(($event: any) => (_ctx.onExport(record)), ["prevent"])
            }, "下载", 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["columns", "data"])
      ])
    ]),
    _: 1
  }))
}