
import { defineComponent, onMounted, computed, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { TIPS_LIST } from '@/utils/constants';
import { EnterpriseInfo } from '@/store/modules/user';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { message } from 'ant-design-vue';
import { assignWithDefault } from '@/utils/illuminate';


const defaultFinanceDetail = {
  availableAmount: 0,
  childEnterpriseBalance: 0,
  frozenAmount: 0,
  preferentialAmount: 0,
  totalBalance: 0,
  invoice: 0,
  financeDetail: 0,
  childEnterpriseAssignedQuota: 0,
};

export default defineComponent({
  name: 'OverviewViewStatement',
  setup(props, ctx) {
    const store = useStore();
    const allEnterprise = ref([]);
    const childEnterprise = ref([]);
    const filterParams = reactive<{
      allEnterpriseId: number | null | string,
      childEnterpriseId: number | string,
    }>({
      allEnterpriseId: store.state.User.enterprise.enterpriseId, // 默认选中“全部”；
      childEnterpriseId: '',
    });
    const financeDetail = ref({
      ...defaultFinanceDetail,
    });
    const model = ref('enterprise');


    const getEnterprise = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-child-enterprise'), { params: { enterpriseId: store.state.User.enterprise.enterpriseId } });
        allEnterprise.value = res.data;
        const childData = JSON.parse(JSON.stringify(res.data));
        const index = childData.findIndex((item : any) => item.id === store.state.User.enterprise.enterpriseId);
        if (index !== -1) {
          childData.splice(index, 1, { id: '', enterpriseName: '全部' });
        }
        childEnterprise.value = childData;
      } catch (e: Error & any) {
        return message.error(e.message);
      }

    };

    const loadFinanceDetail = async (params = {}) => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/finance/find-finance-detail'), { params });
        switch (model.value) {
          case 'childEnterprise': 
            financeDetail.value.childEnterpriseBalance = res.data.childEnterpriseBalance || 0;
            financeDetail.value.childEnterpriseAssignedQuota = res.data.childEnterpriseAssignedQuota || 0;
            break;
          case 'invoice':
            financeDetail.value.invoice = res.data.invoice || 0;
            break;
          default: 
            assignWithDefault(financeDetail.value, res.data, defaultFinanceDetail);
            break;
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    const maxAmountLength = computed(() => {
      const detail = financeDetail.value;
      return Math.max(0, detail.availableAmount, detail.frozenAmount, detail.invoice).toString().length;
    });

    watch(() => store.state.User.enterprise, (val: EnterpriseInfo) => {
      if (val && val.enterpriseId) {
        model.value = 'enterprise';
        loadFinanceDetail();
        getEnterprise();
        filterParams.allEnterpriseId = val.enterpriseId;
        filterParams.childEnterpriseId = '';
      }
    });

    const changeEnterprise = async (e : any, type : string) => {
      model.value = type;
      if (type === 'childEnterprise') filterParams.childEnterpriseId = e;
      else filterParams.allEnterpriseId = e;
      loadFinanceDetail({ queryEnterpriseId: e });
    };

    const filterOption = (input: string, option: any) => option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    onMounted(() => {
      loadFinanceDetail();
      getEnterprise();
    });

    return {
      TIPS_LIST,
      maxAmountLength,
      filterParams,
      changeEnterprise,
      allEnterprise,
      childEnterprise,
      financeDetail,
      loadFinanceDetail,
      filterOption,
    };
  },
});
