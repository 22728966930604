
import { message, Modal } from 'ant-design-vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';
import { defineComponent, ref, createVNode, getCurrentInstance, onMounted } from 'vue';
import { createApiUrl } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { useUtils } from '@/core/utils';
import { formatDate } from '@/core/filters';
import Verify from '@/components/Verify/index.vue';
import ViewLinkModal from './ViewLinkModal.vue';
import DetailDrawer from './DetailDrawer.vue';
import AlreadyJoinModal from './AlreadyJoinModal.vue';

export default defineComponent({
  name: 'InviteLinkListView',
  components: { AlreadyJoinModal, ViewLinkModal, DetailDrawer, Verify },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const captchaType = ref('');
    const verify = ref<any>(null as any);
    const utils = useUtils();
    const activeKey = ref(1);
    // const switchFlag = ref(false);

    // 加载数据方法 必须为 Promise 对象
    const loadLinkTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/invitation/invitation-find-list'), { params: parameter })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    // 加载数据方法 必须为 Promise 对象
    const loadNumberTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/invitation/findInvitationList'), {
        params: parameter,
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    // 链接邀请的table
    const linkTableRef = ref<any>(null);
    const onRefreshLinkTable = () => {
      linkTableRef.value.refresh(true);
    };

    // 手机号码/邮箱的table
    const numberTableRef = ref<any>(null);
    const onRefreshNumberTable = () => {
      numberTableRef.value.refresh(true);
    };

    const linkTableColumns: TableColumn[] = [
      { title: '公司', dataIndex: 'enterpriseName' },
      { title: '链接名称', dataIndex: 'invitationName' },
      { title: '已加入员工', dataIndex: 'num', slots: { customRender: 'num' } },
      {
        title: '状态',
        dataIndex: 'state',
        customRender: utils.createTagSlot({
          OPEN: { color: 'green', text: '启用' },
          CLOSE: { color: 'red', text: '停用' },
        }),
      },
      { title: '创建人', dataIndex: 'createUser' },
      { title: '创建时间', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text) },
      { title: '启用/停用', dataIndex: 'switch', slots: { customRender: 'switch' } },
      { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const numberTableColumns: TableColumn[] = [
      { title: '公司', dataIndex: 'enterpriseName' },
      { title: '手机号码/邮箱', dataIndex: 'channel' },
      { title: '员工状态', dataIndex: 'employesStatus', customRender: ({ text }) => (text === 1 ? '加入' : '未加入') },
      { title: '创建时间', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text) },
      { title: '邀请次数', dataIndex: 'invitationNum' },
      { title: '创建人', dataIndex: 'invitationUserName' },
      { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    const viewLinkModalRef = ref<any>(null as any);
    const onViewLink = (record: any) => {
      viewLinkModalRef.value.view(record);
    };

    const detailDrawerRef = ref<any>(null as any);
    const onView = (record: any) => {
      detailDrawerRef.value.view(record);
    };

    const onSwitchState = async (record: any) => {
      try {
        const res = await request.put(createApiUrl('/newlinkSass/invitation/invitation-update'), {
          id: record.id,
          enterpriseId: record.enterpriseId,
          state: record.state === 'OPEN' ? 'CLOSE' : 'OPEN',
        });
        if (res.code === 200 && record.state !== 'OPEN') message.success('开启成功');
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      onRefreshLinkTable();
    };

    // 撤销邀请
    const onCancelInvite = (id: string, invitationCode: string) => {
      Modal.confirm({
        title: '确认撤销该员工的邀请 ？',
        icon: createVNode(QuestionCircleFilled),
        content: '撤销后，员工不能通过该邀请加入企业',
        async onOk() {
          try {
            const res: any = await request.get(createApiUrl('/newlinkSass/invitation/cancelInvitation'), {
              params: { ...{ id }, ...{ invitationCode } },
            });
            if (res.code === 200) message.success(res.data);
          } catch (e: Error & any) {
            return message.error(e.message);
          } finally {
            onRefreshNumberTable();
          }
        },
        onCancel() {
        //
        },
      });
    };

    // 重发邀请
    const InviteId = ref('');
    const onResendInvite = (id: string) => {
      captchaType.value = 'blockPuzzle';
      InviteId.value = id;
      verify.value.show();
    };

    // 员工加入审核
    const alreadyJoinModalRef = ref<any>(null as any);
    const onAlreadyJoin = (record: any) => {
      alreadyJoinModalRef.value.show(record);
    };


    // 验证码成功
    const handleSuccess = async () => {
      try {
        const res: any = await request.get(createApiUrl('/newlinkSass/invitation/reSendInvitation'), {
          params: { id: InviteId.value },
        });
        if (res.code === 200) message.success(res.data);
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      onRefreshNumberTable();
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_staff_invitelist_page_view', { page_name: '员工_邀请记录页_浏览' });
    });

    return {
      activeKey,

      numberTableRef,
      numberTableColumns,
      loadNumberTableData,
      onCancelInvite,
      onResendInvite,

      alreadyJoinModalRef,
      onAlreadyJoin,

      linkTableRef,
      linkTableColumns,
      loadLinkTableData,

      viewLinkModalRef,
      onViewLink,

      detailDrawerRef,
      onView,

      onSwitchState,

      verify,
      captchaType,
      handleSuccess,
    };
  },
});
