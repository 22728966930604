import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "QuotaAdjustment__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_regular_quota = _resolveComponent("regular-quota")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: true,
    width: "656px",
    height: "434px",
    footer: null,
    onCancel: _ctx.closeModal
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(`额度管理-（${_ctx.rowDetail.arName}）`), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_regular_quota, {
        ref: "RegularQuotaRef",
        "row-detail": _ctx.rowDetail,
        onCloseModal: _ctx.closeModal
      }, null, 8, ["row-detail", "onCloseModal"])
    ]),
    _: 1
  }, 8, ["onCancel"]))
}