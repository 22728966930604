import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "departmentMenu" }
const _hoisted_2 = { class: "departmentMenu__companyName fl-row-between" }
const _hoisted_3 = {
  ref: "companyTreeWrap",
  class: "departmentMenu__companyTreeWrap"
}
const _hoisted_4 = { class: "searchWrap" }
const _hoisted_5 = { class: "addStaffModal__departmentWrap" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { style: {"color":"#008dff"} }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "departmentMenu__departmentList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_popover, {
      visible: _ctx.companyTreeVisible,
      placement: "bottom",
      title: null,
      trigger: "click",
      "overlay-style": { transformOrigin: 'top center' }
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_input, {
              value: _ctx.companyKeywords,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyKeywords) = $event)),
              placeholder: "企业名称",
              "allow-clear": ""
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_tree, {
              "default-expand-all": "",
              "show-icon": false,
              "show-line": true,
              "auto-expand-parent": true,
              "selected-keys": _ctx.selectedCompanyIds,
              "tree-data": _ctx.enterpriseTree,
              onSelect: _ctx.onSelectCompany
            }, {
              title: _withCtx((scop) => [
                (scop.title.indexOf(_ctx.companyKeywords) > -1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(scop.title.substr(0, scop.title.indexOf(_ctx.companyKeywords))) + " ", 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.companyKeywords), 1),
                      _createTextVNode(" " + _toDisplayString(scop.title.substr(scop.title.indexOf(_ctx.companyKeywords) + _ctx.companyKeywords.length)), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(scop.title), 1))
              ]),
              _: 1
            }, 8, ["selected-keys", "tree-data", "onSelect"])
          ])
        ], 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_2, [
          _createElementVNode("span", {
            class: "text-ellipsis",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetDepartment && _ctx.resetDepartment(...args)))
          }, _toDisplayString(_ctx.enterpriseName), 1),
          (_ctx.authority === 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                ref: "switchCompanyBtn",
                class: "switchCompanyBtn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCompanyTreeVisible && _ctx.toggleCompanyTreeVisible(...args)))
              }, [
                _createVNode(_component_app_icon, { type: "transformation" })
              ], 512))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("ul", _hoisted_9, [
      (_ctx.departmentList.length)
        ? (_openBlock(), _createBlock(_component_a_tree, {
            key: 0,
            modelValue: _ctx.checkedKeys,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkedKeys) = $event)),
            "show-icon": false,
            "show-line": true,
            "auto-expand-parent": false,
            "expanded-keys": _ctx.expandedDepartmentIds,
            "selected-keys": _ctx.selectedDepartmentIds,
            "tree-data": _ctx.departmentList,
            onSelect: _ctx.onSelectItem,
            onExpand: _ctx.onExpandDepartment
          }, null, 8, ["modelValue", "expanded-keys", "selected-keys", "tree-data", "onSelect", "onExpand"]))
        : (!_ctx.withDefault)
          ? (_openBlock(), _createBlock(_component_a_empty, {
              key: 1,
              description: "暂无部门",
              image: _ctx.emptyImage
            }, null, 8, ["image"]))
          : _createCommentVNode("", true)
    ])
  ]))
}