import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f9d72e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: $setup.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.visible) = $event)),
    "destroy-on-close": "",
    title: "查看已加入员工",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "id",
        columns: $setup.tableColumns,
        data: $setup.loadTableData,
        "show-pagination": true
      }, {
        dateTime: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString($setup.formatDate(record.invitationDateTime) || $setup.formatDate(record.applyDateTime)), 1)
        ]),
        _: 1
      }, 8, ["columns", "data"]),
      ($setup.total)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode("共计："),
            _createElementVNode("b", null, _toDisplayString($setup.total) + "人", 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}