
import { defineComponent, computed, reactive, ref, onMounted, onUnmounted, nextTick } from 'vue';
import { message, Empty } from 'ant-design-vue';
import { on, off } from '@/utils/eventBus';
import { settleTree } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { createApiUrl, isParentOf } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import { DepartmentItem, EVENT_LIST } from '@/utils/constants';

import './departmentMenu.scss';
import { EnterpriseItem, useSubsidiary } from '@/views/parts/subsidiary';

export default defineComponent({
  name: 'DepartmentMenuNew',
  props: {
    withDefault: { type: Boolean, default: false },
    showAddUser: { type: Boolean, default: false },
  },
  emits: [
    'resetDepartment',
    'changeDepartment',
    'changeCompany',
    'getObdStatus',
    'cancelSelected',
  ],
  setup(props, ctx) {
    const store = useStore();
    const authority = ref(store.state.User.authority);
    const state = useState();
    const localEnterprise = ref<EnterpriseItem | null>(null);

    /* = ---------------------------- = 部门部分逻辑 = ---------------------------- = */
    const departmentList = ref<(DepartmentItem & any)[]>([]);
    const expandedDepartmentIds = ref<number[]>([]);
    const selectedDepartmentIds = ref<number[]>([]);
    const loadEnterpriseDepartment = async () => {
      let res: any;
      let data: any;
      try {
        res = await request.get(
          createApiUrl('/newlinkSass/enterprise/find-enterprise-department'),
          { params: { enterpriseId: localEnterprise.value?.id || state.enterprise.value.enterpriseId } },
        );
        // 如果是预付卡公司，添加临时用户
        if (props.showAddUser) {
          data = [
            {
              phone: '临时用户',
              id: 'temporaryUser',
              departmentList: [],
            },
          ];
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName' },
        'departmentList');

      if (props.showAddUser) {
        departmentList.value.push(...settleTree(data, { key: 'id', title: 'phone' }, 'departmentList'));
      }

      if (props.withDefault && authority.value === 0) {
        departmentList.value.unshift({
          key: 0,
          title: '未分配',
        });
      }
    };

    // 部门相关逻辑
    const onChangeDepartment = (departmentId: number, title: string) => {
      ctx.emit('changeDepartment', departmentId, title);
    };

    const resetDepartment = () => {
      selectedDepartmentIds.value = [];
      onChangeDepartment(-1, localEnterprise.value?.enterpriseName || state.enterprise.value.enterpriseName);
    };

    const onSelectItem = (keys: number[], e: any) => {
      selectedDepartmentIds.value = keys;
      if (!e.selectedNodes[0]) {
        resetDepartment();
        return;
      }
      onChangeDepartment(keys[0], e.selectedNodes[0]?.props.title);
    };

    const onExpandDepartment = (keys: number[]) => {
      expandedDepartmentIds.value = keys;
    };

    /* = ---------------------------- = 切换公司 = ---------------------------- = */
    const subsidiaryState = useSubsidiary();
    const companyTreeVisible = ref(false);
    const switchCompanyBtn = ref<HTMLButtonElement>(null as any);
    const companyTreeWrap = ref<HTMLDivElement>(null as any);
    const companyKeywords = ref('');
    const selectedCompanyIds = ref<number[]>([]);
    const enterpriseName = computed(() => localEnterprise.value?.enterpriseName || state.enterprise.value.enterpriseName);
    const enterpriseList = ref<(EnterpriseItem & any)[]>([]);
    const enterpriseTree = ref<(EnterpriseItem & any)[]>([]);

    const clockAway = (e: MouseEvent) => {
      if (
        !isParentOf(e.target as Node, switchCompanyBtn.value)
        && !isParentOf(e.target as Node, companyTreeWrap.value)
      ) {
        companyTreeVisible.value = false;
        document.removeEventListener('mousedown', clockAway);
      }
    };

    const toggleCompanyTreeVisible = () => {
      companyTreeVisible.value = !companyTreeVisible.value;
      if (companyTreeVisible.value) {
        document.addEventListener('mousedown', clockAway);
      } else {
        document.removeEventListener('mousedown', clockAway);
      }
    };

    const onSelectCompany = (ids: number[]) => {
      selectedCompanyIds.value = ids;
      enterpriseList.value.some(i => {
        if (i.id !== ids[0]) return;
        localEnterprise.value = i;
        loadEnterpriseDepartment();
        ctx.emit('changeCompany', i);
        return true;
      });
      companyTreeVisible.value = false;
      ctx.emit('getObdStatus', localEnterprise.value?.id || state.enterprise.value.enterpriseId);
    };

    onMounted(async () => {
      loadEnterpriseDepartment();
      on(EVENT_LIST.DEPARTMENT_CHANGE, loadEnterpriseDepartment);
      const data = await subsidiaryState.loadEnterpriseTreeData();
      enterpriseList.value = data.list;
      enterpriseTree.value = data.tree;
    });

    onUnmounted(() => {
      off(EVENT_LIST.DEPARTMENT_CHANGE, loadEnterpriseDepartment);
    });

    return {
      authority,

      enterpriseTree,
      enterpriseName,
      checkedKeys: [],

      companyTreeVisible,
      companyKeywords,
      switchCompanyBtn,
      companyTreeWrap,
      selectedCompanyIds,
      toggleCompanyTreeVisible,
      onSelectCompany,

      resetDepartment,
      onChangeDepartment,
      onSelectItem,
      onExpandDepartment,
      departmentList,
      expandedDepartmentIds,
      selectedDepartmentIds,

      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
});
