
import { defineComponent, reactive, ref, computed, watch } from 'vue';
import { message, Form } from 'ant-design-vue';
import { emit } from '@/utils/eventBus';
import { settleTree } from '@/utils/illuminate';

import { AMOUNT_FINITE_TYPE, EVENT_LIST } from '@/utils/constants';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import { useDepartment } from '@/views/main/department/utils';

function disableTreeNode(tree: any[], valList: any[]) {
  tree.forEach(item => {
    item.disabled = valList.indexOf(item.value) !== -1;
    item.children && disableTreeNode(item.children, valList);
  });
}

// 部门主管 - 企业员工数（禁用tree父级标签）
function disableTreeClass(tree: any[], valList: any[]) {
  tree.forEach((item, index) => {
    item.children && disableTreeClass(item.children, valList);
    if (item.children && item.children.length > 0) {
      item.disabled = true;
    }
    if (!(item.departmentName && item.accountInfoList && item.children)) {
      if (item.departmentName) {
        item.disabled = true;
      }
    }
  });
}

export default defineComponent({
  name: 'DepartmentActionDrawer',
  components: {},
  emits: ['complete'],
  setup(props, ctx) {
    const state = useState();
    const departmentState = useDepartment();

    const action = ref('view');
    const visible = ref(false);

    const getDefaultFormData = (): {
      enterpriseId: number | undefined,
      enterpriseName: string,
      departmentName: string,
      parentDepartmentId: number | undefined,
      parentDepartmentsId: number | undefined,
      managerUserCode: string | undefined,
    } => ({
      enterpriseId: state.enterprise.value?.enterpriseId,
      enterpriseName: '',
      departmentName: '',
      parentDepartmentId: undefined,
      parentDepartmentsId: undefined,
      managerUserCode: undefined,
    });

    const titleMap: Record<string, string> = {
      view: '部门详情',
      create: '新增部门',
      edit: '编辑部门',
    };
    const title = computed<string>(() => titleMap[action.value]);

    let recordId = 0;
    const formData = reactive({
      ...getDefaultFormData(),
    });

    const dataLoading = ref(false);
    /* = -------------------------------------------------- = 企业部门列表 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    // 加载企业部门列表
    const loadEnterpriseDepartment = async () => {
      let res: any;
      dataLoading.value = true;

      try {
        res = await request.get(
          createApiUrl('/newlinkSass/enterprise/find-enterprise-department'),
          { params: { enterpriseId: formData.enterpriseId || state.enterprise.value.enterpriseId } },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        dataLoading.value = false;
      }
      departmentList.value = [];
      departmentList.value = settleTree(
        res.data.departmentList.filter((item: any) => item.defaultFlag),
        { value: 'id', title: 'departmentName', label: 'departmentName' },
        'departmentList',
      );

      if (action.value) {
        disableTreeNode(departmentList.value, [recordId]);
      }
    };

    /* = -------------------------------------------------- = 企业员工列表 = -------------------------------------------------- = */
    const setTreeNode = (tree: any) => {
      const departmentList = tree.departmentList || [];
      const accountInfoList = tree.accountInfoList || [];
      tree.departmentAccountList = [...departmentList, ...accountInfoList];
      tree.departmentList && tree.departmentList.length > 0 && tree.departmentList.map((item: any) => {
        item.userCode = item.id;
        item.userName = item.departmentName;
        setTreeNode(item);
      });
    };
    const userCodeList = ref<any[]>([]);
    // 加载企业员工列表
    const loadEnterpriseUserCode = async () => {
      let res: any;
      dataLoading.value = true;

      try {
        res = await request.get(
          createApiUrl('/newlinkSass/enterprise/find-enterprise-department'),
          {
            params: {
              enterpriseId: formData.enterpriseId || state.enterprise.value.enterpriseId,
              withUserInfoFlag: 1,
            },
          },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        dataLoading.value = false;
      }
      res.data.departmentList.map((item: any) => {
        item.userCode = item.id;
        item.userName = item.departmentName;
        setTreeNode(item);
      });
      userCodeList.value = [];
      userCodeList.value = settleTree(
        res.data.departmentList.filter((item: any) => (item.departmentList || item.accountInfoList)),
        { value: 'userCode', title: 'userName', label: 'userName' },
        'departmentAccountList',
      );

      if (action.value) {
        disableTreeClass(userCodeList.value, [recordId]);
      }
    };

    watch(
      () => formData.enterpriseId,
      () => {
        formData.parentDepartmentId = undefined;
        formData.managerUserCode = undefined;
        if (visible.value === true) {
          loadEnterpriseDepartment();
          loadEnterpriseUserCode();
        }
      },
    );

    // = ---------------------------- = 显示入口 = ---------------------------- =
    const editorFormRef = ref<typeof Form>(null as any);
    const doWith = async (actionName: string, id = 0) => {
      recordId = id;
      action.value = actionName;
      visible.value = true;
      editorFormRef.value?.clearValidate();
      Object.assign(formData, getDefaultFormData());

      const promiseList: Promise<any>[] = [];

      dataLoading.value = true;
      if (actionName === 'view') {
        promiseList.push(departmentState.loadDepartmentDetail(id));
      } else if (actionName === 'create' || actionName === 'edit') {
        promiseList.push(loadEnterpriseDepartment());
        promiseList.push(loadEnterpriseUserCode());
      } else {
        promiseList.push(departmentState.loadDepartmentDetail(id));
      }

      await Promise.all(promiseList);
      dataLoading.value = false;

      if (actionName !== 'create') {
        Object.assign(formData, {
          ...departmentState.departmentDetail,
          parentDepartmentId: departmentState.departmentDetail.parentDepartmentId || undefined,
          managerUserCode: departmentState.departmentDetail.managerUserCode || undefined,
        });
      }
    };

    const onSwitchEdit = () => {
      doWith('edit', recordId);
    };

    const onSwitchCancel = () => {
      if (action.value === 'edit') {
        action.value = 'view';
      } else {
        visible.value = false;
      }
    };

    const resetFields = () => {
      recordId = 0;
      dataLoading.value = false;
      Object.assign(formData, { ...getDefaultFormData() });
    };

    const rules = {
      departmentName: [{ required: true, message: '请输入部门名' }],
    };

    // = ---------------------------- = 提交表单 = ---------------------------- =
    const onSubmit = async () => {
      if (dataLoading.value) return;

      //
      editorFormRef.value.validate()
        .then(async () => {
          const body: any = {
            parentDepartmentId: formData.parentDepartmentId || 0,
            userCode: formData.managerUserCode,
            // 冗余数据 只是未了让新建接口不报错
            amountFiniteSate: AMOUNT_FINITE_TYPE.INFINITE,
            finiteAmount: 0,
          };
          if (action.value === 'create') {
            body.enterpriseId = formData.enterpriseId;
            body.enterpriseName = '';
            body.departmentName = formData.departmentName;
          } else {
            Object.assign(body, formData);
          }

          action.value === 'edit' && (body.id = recordId);

          dataLoading.value = true;
          try {
            await request.put(createApiUrl('/newlinkSass/department/create-or-update-department'), body);
          } catch (e: Error & any) {
            return message.error(e.message);
          } finally {
            dataLoading.value = false;
          }

          message.success(`部门${action.value === 'create' ? '新建' : '编辑'}完成`);
          visible.value = false;
          resetFields();
          ctx.emit('complete');
          emit(EVENT_LIST.DEPARTMENT_CHANGE);
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        });
    };

    return {
      ...departmentState,

      labelCol: { span: 5 },
      wrapperCol: { span: 16 },

      title,
      action,

      visible,
      dataLoading,

      formData,
      rules,
      departmentList,
      userCodeList,

      loadEnterpriseDepartment,
      loadEnterpriseUserCode,

      doWith,
      onSwitchEdit,
      onSwitchCancel,
      resetFields,

      editorFormRef,
      onSubmit,
    };
  },
});
