
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import { useDepartment } from '@/views/main/department/utils';

export default defineComponent({
  name: 'DepartmentDelModal',
  emits: ['confirm'],
  setup(props, ctx) {
    const departmentState = useDepartment();
    const visible = ref(false);
    const loading = ref(false);
    const formData = reactive({
      area: [],
      name: '',
      code: '',
    });

    let recordId = 0;

    const del = async (id: number) => {
      visible.value = true;
      recordId = id;

      try {
        loading.value = true;
        await departmentState.loadDepartmentDetail(id);
      } catch (e: Error & any) {
        //
      } finally {
        loading.value = false;
      }
    };

    const onOK = async () => {
      try {
        await departmentState.delDepartment(recordId);
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      visible.value = false;
      message.success('删除完成');
      ctx.emit('confirm');
    };

    return {
      ...departmentState,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },

      visible,
      formData,
      loading,

      del,
      onOK,
    };
  },
});
