
import { defineComponent, onMounted, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'OverviewViewCardList',
  setup(props, ctx) {
    const store = useStore();
    return {};
  },
});
