
import { defineComponent, computed, reactive, ref, onMounted, onUnmounted, watch } from 'vue';
import { message, Modal, Form } from 'ant-design-vue';
import type { TableColumn } from '@/components/Table';
import { dispatchDownload } from '@/utils/illuminate';
import Axiox, { CancelTokenSource } from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';
import { request } from '@/utils/request';
import { useStore } from 'vuex';
import { useCurrentEnterpriseHook, useState } from '@/store';
import { createApiUrl, firstError, isValidateError, toApiUnit, toLocalUnit } from '@/utils/utils';
import { RuleObject } from 'ant-design-vue/es/form/interface';

import DepartmentalStructure from './DepartmentalStructure.vue';
import DepartmentalMember from './DepartmentalMember.vue';
import BatchUpload from './BatchUpload.vue';
import SaveTemplate from './SaveTemplate.vue';
import QuotaMange from './QuotaMange.vue';


type TablePagination = {
  current?: number;
  pageSize?: number;
  pageSizeOptions?: string[];
};

type TableSorter = {
  field?: string;
  order?: string;
};


const generalColumns: TableColumn[] = [
  { title: '姓名', dataIndex: 'arName', ellipsis: true },
  { title: '手机号', dataIndex: 'phone' },
  { title: '工号', dataIndex: 'jobNumber' },
  { title: '部门', dataIndex: 'departmentNameStr', ellipsis: true },
  { title: '可用额度增减', dataIndex: 'adjustmentAmount', slots: { customRender: 'adjustmentAmount' }, width: '150px' },
  { title: '操作', dataIndex: 'action', width: '170px', slots: { customRender: 'action' } },
];
const regularColumns: TableColumn[] = [
  { title: '姓名', dataIndex: 'arName', ellipsis: true },
  { title: '手机号', dataIndex: 'phone' },
  { title: '工号', dataIndex: 'jobNumber' },
  { title: '部门', dataIndex: 'departmentNameStr', ellipsis: true },
  { title: '定期额度', dataIndex: 'adjustmentAmount', customRender: ({ text, record }) => (record.activate ? text : '--'), width: '150px' },
  { title: '操作', dataIndex: 'action', width: '170px', slots: { customRender: 'action' } },
];

export default defineComponent({
  name: 'QuataAdjustment',
  components: {
    DepartmentalStructure,
    DepartmentalMember,
    BatchUpload,
    SaveTemplate,
    QuotaMange,
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const state = useState();
    const query = { ...route.query };
    const quotaType = sessionStorage.getItem('quota_type');
    const templateId = ref('');
    const formData = reactive({ loadTableData: [] });
    const paginationParams = reactive({ current: 1, pageNo: 1, pageSize: 10, total: 0 });
    const tableRef = ref<any>(null as any);
    const memberList = ref([]);
    const currentDepartment = ref<{ id: number| undefined}>({ id: undefined });
    const changeTemplate = ref(false);
    const departmentMenu = ref<any>(null as any);
    const uploadVisible = ref(false);
    const exporting = ref(false);
    let exportSource: CancelTokenSource | null;
    const formRef = ref();
    const saveTemplateVisible = ref(false);
    const templateInfo = reactive<{ departmentSum: number, employeeCount: number }>({ departmentSum: 0, employeeCount: 0 }); 
    const quotaMangeVisible = ref(false);
    const rowDetail = ref({});
    const deptAllChildNodeFlag = ref(false);
    const loading = ref(false);
    const templateName = ref(query.type === 'edit' ? query.name : '');
    const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId')) || state.enterprise.value.enterpriseId;

    const defaultRoute = [
      {
        name: 'main.staff.list',
        breadcrumbName: '员工',
      }, {
        name: 'main.staff.quota_adjustment',
        breadcrumbName: quotaType === 'general' ? '普通额度调整' : '定期额度调整',
      },
    ];

    const editRoute = [
      {
        name: 'main.staff.quota_adjustment',
        breadcrumbName: quotaType === 'general' ? '普通额度调整' : '定期额度调整',
      }, {
        name: 'main.staff.template_list',
        breadcrumbName: '模版列表',
      }, {
        name: '',
        breadcrumbName: '编辑',
      },
    ];
    // 路由地址
    const routes = query.type === 'edit' ? editRoute : defaultRoute;
    // 自定义校验
    const validatePass = async (rule: RuleObject, value: string) => {
      if (value.toString() === '0') {
        return Promise.reject('可用额度增减不能为0！');
      }
      if (!Number(value)) {
        return Promise.reject('请输入可用额度增减！');
      }
      if (!/^(-|\+?)\d+(\.\d{0,2})?$/.test(value.toString())) {
        return Promise.reject('只能输入数字和正负号，最多两位小数');
      } 
      return Promise.resolve();
    };

    const restriction = () => {
      const sign = formData.loadTableData.some((i: any) => {
        if (quotaType === 'general') return i.adjustmentAmount.toString() === '0';
        return i.activate && i.adjustmentAmount.toString() === '0';
      });
      const flag = formData.loadTableData.every((i: any) => {
        if (quotaType === 'general') return Number(i.adjustmentAmount);
        if (i.activate) return Number(i.adjustmentAmount);
        return true;
      });
      if (sign) { 
        message.error('不能提交额度金额为0的数据～');
        return false;
      }
      if (!flag) { 
        message.error('不能提交额度金额为空的数据～');
        return false;
      }
      return true;
    };

    // 表格列表
    const tableColumns = computed<TableColumn[]>(() => {
      if (quotaType === 'general') return generalColumns;
      return regularColumns;
    });

    // // 监听路由变化
    watch(() => router.currentRoute.value, (newValue, oldValue) => {
      if (newValue.path === oldValue.path && !uploadVisible.value) {
        router.go(0);
      }
    });

    // 获取模版数据
    const getTableData = async (parameter: any) => {
      try {
        const res = await Promise.all([
          request.get(createApiUrl('/newlinkSass/quota/ar/batch/getMemberList'), { params: { templateId: templateId.value, ...parameter } }),
          request.get(createApiUrl('/newlinkSass/quota/ar/get/template/info'), { params: { templateId: templateId.value } }),
        ]);
        const { list, total, pageNum, pageSize } = res[0].data;
        const { departmentSum, employeeCount } = res[1].data;

        // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页
        if (list && list.length === 0 && paginationParams.current > 1) {
          paginationParams.current--;
          paginationParams.pageNo--;
          getTableData(paginationParams);
          return;
        }
        formData.loadTableData = list.map((i:any) => ({ ...i, adjustmentAmount: toLocalUnit(i.adjustmentAmount) }));
        paginationParams.total = total;
        paginationParams.pageNo = pageNum;
        paginationParams.current = pageNum;
        paginationParams.pageSize = pageSize;
        templateInfo.departmentSum = departmentSum;
        templateInfo.employeeCount = employeeCount;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    // 分页、排序、筛选
    const handleTableChange = (pagination: TablePagination, filters: [], sorter: TableSorter) => {
      const pageNo = (pagination && pagination.current) || paginationParams.current;
      const pageSize = (pagination && pagination.pageSize) || paginationParams.pageSize;
      getTableData({ pageNo, pageSize });
    };
    // 点击部门，获取部门员工
    const onSetDepartment = async (id: number, current: any) => {
      try {
        const res = await request.get(createApiUrl('/newlinkSass/staff/find-all-user-by-parm'), {
          params: {
            enterpriseId,
            departmentId: id || undefined,
          },
        });
        memberList.value = res.data;
        currentDepartment.value = current;
        changeTemplate.value = true;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    // 返回部门
    const backDepartment = () => {
      changeTemplate.value = false;
    };
    // 操作员工
    const operateMember = async (type: string, value: any) => {
      try {
        let url = '';
        let params = {};  
        let res :any;  
        loading.value = true;
        switch (type) {
          case 'add': 
            url = '/quota/ar/addBatchTemplateMember';
            params = { templateId: templateId.value, accountInfoId: value.id, enterpriseId, timelinessFlag: 1 };
            res = await request.post(createApiUrl(`/newlinkSass${url}`), params);
            break;
          case 'delete': 
            url = '/quota/ar/deleteTemplateMember';
            params = { memberId: value.id, enterpriseId };
            res = await request.get(createApiUrl(`/newlinkSass${url}`), { params });
            break;
          case 'all': 
            url = '/quota/ar/addBatchTemplateMember-list';
            params = value.map((item:any) => ({ templateId: templateId.value, accountInfoId: item.id, enterpriseId }));
            res = await request.post(createApiUrl(`/newlinkSass${url}`), params);
            break;
          case 'clear':
            url = '/quota/ar/emptyTemplateMember';
            params = { templateId: templateId.value, enterpriseId };
            res = await request.get(createApiUrl(`/newlinkSass${url}`), { params });
            break;
          default:
            break;
        }
        loading.value = false;
        message.success(res.msg);
        formRef.value.clearValidate();
        getTableData({ current: 1, pageNo: 1, pageSize: 10 });
      } catch (e: Error & any) {
        message.error(e.message);
        loading.value = false;
      }
    };
    // 编辑金额
    const editAvailableAmount = async (record: Record<string, any>, index: number) => {
      try {
        if (record.adjustmentAmount.toString() === '0') return message.error('增减额度不能为0');
        if (!Number(record.adjustmentAmount)) return message.error('增减额度不能为空');
        if (!/^(-|\+?)\d+(\.\d{0,2})?$/.test(record.adjustmentAmount)) return message.error('只能输入数字和正负号，最多两位小数');
        const res = await request.post(createApiUrl('/newlinkSass/quota/ar/updateBatchTemplateMember'), { memberId: record.id, adjustmentAmount: toApiUnit(record.adjustmentAmount) });
        getTableData({ ...paginationParams });
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    // 保存模版关闭
    const close = () => {
      saveTemplateVisible.value = false; 
    };
    // 提交模版
    const handleOk = async (data: any) => {
      try {
        const res = await request.post(createApiUrl('/newlinkSass/quota/ar/batch/saveTemplate'), { templateId: templateId.value, ...data });
        close();
        router.push({ name: 'main.staff.template_list' }); 
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };
    // 保存模版
    const saveTemplate = async () => {
      if (!formData.loadTableData.length) return message.error('暂无数据，请先添加员工！');
      const bank = restriction();
      if (!bank) return;
      try {
        await formRef.value.validate();
        if (query.type !== 'edit') {
          saveTemplateVisible.value = true;
        } else {
          const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
          console.log('reg', reg.test(templateName.value ? templateName.value.toString() : ''));
          if (!templateName.value || reg.test(templateName.value ? templateName.value.toString() : '')) return message.error('请填写模版名称且不允许输入表情');
          handleOk({ templateName: templateName.value });
        }
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };
    // 执行
    const execute = async () => {
      if (!formData.loadTableData.length) return message.error('暂无数据，请先添加员工！');
      const bank = restriction();
      if (!bank) return;
      formRef.value.validate()
        .then(() => {
          Modal.confirm({
            title: '执行',
            content: `请确认是否执行，执行人数：${templateInfo.employeeCount}人，涉及部门数：${templateInfo.departmentSum}`,
            okText: '确认执行',
            cancelText: '取消',
            onOk: async () => {
              try {
                const res = await request.get(createApiUrl('/newlinkSass/quota/ar/batch/execute/template'), { params: { templateId: templateId.value } });
                router.push({ name: 'main.staff.execute_log', query: { id: templateId.value, templateName: '-' } });
              } catch (e: Error & any) {
                return message.error(e.message);
              }
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        })
        .catch((e: Error & any) => {
          message.error(firstError(e));
        });
      
    };
    // 批量上传
    const batchUpload = (type: string) => {
      uploadVisible.value = true;
    };
    const toPage = (name: string) => {
      router.push({ name });
    };
    // 批量上传取消
    const handleCancel = () => {
      uploadVisible.value = false;
      getTableData({ current: 1, pageNo: 1, pageSize: 10 });
    };

    // 导出
    const onExport = async () => {
      if (!formData.loadTableData.length) return message.error('暂无数据，请先添加员工！');

      exporting.value = true;
      let res: any;
      message.success('开始导出');
      try {
        const url = quotaType === 'general' ? '/excel/down/ar/quota/normal/detail' : '/excel/down/ar/quota/cycle/detail';
        exportSource = Axiox.CancelToken.source();
        res = await request.get(createApiUrl(`/newlinkSass${url}`), {
          responseType: 'blob',
          timeout: 300000,
          cancelToken: exportSource.token,
          params: { templateId: templateId.value },
        });
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        exporting.value = false;
        exportSource = null;
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload(quotaType === 'general' ? '普通额度调整导出.xlsx' : '定期额度调整导出.xlsx', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      message.success('导出完成');
    };

    // 定期额度管理
    const quotaMange = (record: Record<string, any>) => {
      quotaMangeVisible.value = true;
      rowDetail.value = record;
    };
    const complete = (flag = false) => {
      quotaMangeVisible.value = false;
      flag && getTableData({ ...paginationParams });
    };

    onMounted(async () => {
      if (!query.id) {
        try {
          const res = await request.post(createApiUrl('/newlinkSass/quota/ar/batch/initialization-or-get-template'), {
            templateType: quotaType === 'general' ? 1 : 2,
            enterpriseId,
          });
          templateId.value = res.data;
        } catch (e: Error & any) {
          message.error(e.message);
          throw e;
        }
      } else templateId.value = query.id as string;
      getTableData({ ...paginationParams });
    });

    onUnmounted(() => {
      // 取消未完成的导出操作
      exportSource?.cancel();
    });
    return {
      tableColumns,

      formData,
      loading,
      paginationParams,
      changeTemplate,
      memberList,
      currentDepartment,
      templateInfo,
      query,
      quotaType,
      routes,
      backDepartment,
      operateMember,
      quotaMange,
      editAvailableAmount,
      saveTemplate,
      execute,
      toPage,
      handleOk,

      tableRef,

      // 部门相关逻辑
      departmentMenu,
      onSetDepartment,
      deptAllChildNodeFlag,

      handleTableChange,
      batchUpload,
      templateId,
      uploadVisible,
      quotaMangeVisible,
      rowDetail,
      handleCancel,
      formRef,
      saveTemplateVisible,
      onExport,
      validatePass,
      close,
      complete,
      templateName,
    };
  },
});
