
import { defineComponent, computed, reactive, ref, onMounted, onUnmounted } from 'vue';
import { message, Modal } from 'ant-design-vue';
import type { TableColumn } from '@/components/Table';
import router from '@/router';
import { useRoute } from 'vue-router';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useState } from '@/store';


export default defineComponent({
  name: 'TemplateList',
  components: {

  },
  setup(props, ctx) {
    const route = useRoute();
    const state = useState();
    const query = { ...route.query };
    const quotaType = sessionStorage.getItem('quota_type');
    const tableRef = ref<any>(null as any);
    const totalNum = ref<string | number>(0);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态
    const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId')) || state.enterprise.value.enterpriseId;

    const routes = [{
      name: 'main.staff.quota_adjustment',
      breadcrumbName: quotaType === 'general' ? '普通额度调整' : '定期额度调整',
    }, {
      name: 'main.staff.template_list',
      breadcrumbName: '模版列表',
    }];

    // 表格列表
    const tableColumns = computed<TableColumn[]>(() => {
      const columns: TableColumn[] = [
        { title: '编号', dataIndex: 'id', ellipsis: true },
        { title: '模版名称', dataIndex: 'templateName', ellipsis: true, customRender: ({ text, record }) => `${text}(${record.id})` },
        { title: '部门数', dataIndex: 'departmentSum' },
        { title: '人数', dataIndex: 'employeeCount' },
        { title: '创建人', dataIndex: 'createUserName' },
        { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return columns;
    });

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    // 获取模版数据
    const loadTableData = async (parameter: any) => {
      try {
        totalNum.value = '---';
        const res = await request.get(createApiUrl('/newlinkSass/quota/ar/get/template/page'), { params: { 
          ...parameter, 
          templateType: quotaType === 'general' ? 1 : 2,
          enterpriseId,
        } });
        totalNum.value = res.data.total;
        dataPage.value = res.data.list; // 当前Table数据
        dataState.value = true; // 数据第一次加载
        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    const execute = (record: any) => {
      Modal.confirm({
        title: '执行',
        content: `请确认是否执行${record.templateName}(${record.id})，执行人数：${record.employeeCount}人，涉及部门数：${record.departmentSum}`,
        okText: '确认执行',
        cancelText: '取消',
        onOk: async () => {
          try {
            const res = await request.get(createApiUrl('/newlinkSass/quota/ar/batch/execute/template'), { params: { templateId: record.id } });
            router.push({ name: 'main.staff.execute_log', query: { templateName: record.templateName, id: record.id } });
          } catch (e: Error & any) {
            message.error(e.message);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

    const deleteLog = (record: any) => {
      Modal.confirm({
        title: '删除',
        content: `是否要删除${record.templateName}(${record.id})`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            const res = await request.get(createApiUrl('/newlinkSass/quota/ar/batch/deleteTemplate'), { params: { templateId: record.id } });
            message.success(res.msg as string);
            onRefreshTable();
          } catch (e: Error & any) {
            message.error(e.message);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

    const operate = async (type: string, record: any) => {
      switch (type) {
        case 'execute': 
          execute(record);
          break;
        case 'log': 
          router.push({ name: 'main.staff.execute_log', query: { templateName: record.templateName, id: record.id } });
          break;
        case 'delete': 
          deleteLog(record);
          break;
        case 'edit': 
          router.push({ name: 'main.staff.quota_adjustment', query: { id: record.id, type: 'edit', name: record.templateName } });
          break;
        default: 
          break;
      }
    };

    return {
      tableColumns,
      tableRef,
      routes,
      query,
      loadTableData,
      operate,

    };
  },
});
