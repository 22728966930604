import { defineComponent, ref, reactive } from 'vue';
import { message } from 'ant-design-vue';

import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useState } from '@/store';

export function useAuto() {
  const state = useState();
  const autoList = ref([]);

  const loadAutoList = async (enterpriseId?: number) => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/vehicle/vehicle-find-vehicle-list'), {
        params: {
          // "energyType", value = "车辆能源类型 1加油车 2充电车"
          energyType: state.enterprise.value?.energyTypes,
          enterpriseId: enterpriseId || state.enterprise.value?.enterpriseId,
          state: 'OPEN',
          pageSize: 1000,
        },
      });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    autoList.value = res.data.list.map((i: any) => ({
      ...i,
      key: i.id,
      value: i.id,
      label: i.licensePlate,
    }));
  };

  return {
    autoList,
    loadAutoList,
  };
}

export function useCapacity() {
  const capacityList = ref<{ id: number; displacement: string; oilConsumption: string }[]>([]);

  const loadCapacity = async () => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/vehicle/displacement/list'));
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    capacityList.value = res.data;
  };

  return {
    capacityList,
    loadCapacity,
  };
}
