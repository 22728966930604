
import { defineComponent, onMounted, reactive, ref, watch, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { assignWithDefault } from '@/utils/illuminate';

import { createApiUrl } from '@/utils/utils';
import { MixinDevice } from '@/utils/mixin';
import { request } from '@/utils/request';
import { txcOpen } from '@/utils/txc';

import { EnterpriseInfo } from '@/store/modules/user';
import Guide from './Guide.vue';
import Statement from './Statement.vue';
// import Statistics from './Statistics.vue';
import BasicInfo from './BasicInfo.vue';
import Workspace from './Workspace.vue';
import CardList from './CardList.vue';
import BusinessCard from './BusinessCard.vue';

export default defineComponent({
  name: 'Overview',
  components: {
    Guide,
    CardList,
    Statement,
    // Statistics,
    BasicInfo,
    Workspace,
    BusinessCard,
  },
  mixins: [MixinDevice],
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const authority = ref(store.state.User.authority);

    const defaultOM = {
      accountAskCount: 0,
      accountCount: 0,
      orderDayCount: 0,
      orderMonthCount: 0,
      vehicleCount: 0,
    };

    const overviewMain = reactive({
      ...defaultOM,
    });

    const loadOverviewMain = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/home-page'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      assignWithDefault(overviewMain, res.data, defaultOM);
    };

    watch(() => store.state.User.enterprise, (val: EnterpriseInfo) => {
      (val && val.enterpriseId) && loadOverviewMain();
    });

    onMounted(() => {
      loadOverviewMain();
      instance?.$sensors.track('es_saas_home_page_view', { page_name: '首页_浏览' });
    });

    return {
      authority,

      overviewMain,

      txcOpen,
    };
  },
});
