
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue';
import { PlusCircleOutlined, DoubleLeftOutlined } from '@ant-design/icons-vue';
import { useState } from '@/store';
import { message, Empty } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { debounce } from 'lodash';


export default defineComponent({
  name: 'DepartmentalMember',
  components: {
    PlusCircleOutlined,
    DoubleLeftOutlined,
  },
  props: {
    memberList: { 
      type: Array,
      default: () => ([]),
    },
    currentDepartment: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'operateMember',
    'backDepartment',
  ],
  setup(props, ctx) {
    const list = ref<any[]>([]);
    const userCode = ref();
    const state = useState();
    const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId')) || state.enterprise.value.enterpriseId;

    watchEffect(() => {
      if (props.memberList && props.memberList.length) { 
        list.value = JSON.parse(JSON.stringify(props.memberList));
      }
    });

    watch(() => userCode.value, () => {
      if (!userCode.value) list.value = JSON.parse(JSON.stringify(props.memberList));
    });

    // 模糊搜索
    const handleSearch = (e: any) => {
      if (e.target.value) {
        const params = { nameOrPhone: e.target.value, enterpriseId, departmentId: props.currentDepartment.id };
        request.get(createApiUrl('/newlinkSass/staff/find-all-user-by-parm'), { params: { ...params } }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.msg);
          list.value = data;
        });
      }
    };
    const debouncedGetList = debounce((e: Event) => handleSearch(e), 300, { maxWait: 3000 });
    
    const operateMember = (type: string, val: any) => {
      ctx.emit('operateMember', type, val);
    };

    const backDepartment = (val: any) => {
      ctx.emit('backDepartment', val);
    };

    return {
      userCode,
      list,
      handleSearch,
      operateMember,
      backDepartment,
      debouncedGetList,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
});
