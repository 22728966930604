
import { defineComponent, computed, onMounted, reactive, ref, watch, nextTick, getCurrentInstance, PropType, ComponentPublicInstance } from 'vue';

import { message } from 'ant-design-vue';
import { reqGet, reqCheck } from '@/apis/invitation';
import { RequestError } from '@/utils/exceptions';
import { aesEncrypt } from '../utils/ase';
import { resetSize } from '../utils/util';

//  "captchaType": "blockPuzzle",

type ModalMode = 'pop' | 'fixed';

type SizeOptions = {
  width: string;
  height: string;
}

/**
 * VerifySlide
 * @description 滑块
 */
export default defineComponent({
  name: 'VerifySlide',
  props: {
    captchaType: { type: String, default: 'blockPuzzle' },
    type: { type: String, default: '1' },
    // 弹出式 pop，固定 fixed
    mode: { type: String as PropType<ModalMode>, default: 'fixed' },
    vSpace: { type: Number, default: 5 },
    explain: { type: String, default: '向右滑动完成验证' },
    imgSize: { type: Object as PropType<SizeOptions>, default: () => ({ width: '310px', height: '155px' }) },
    blockSize: { type: Object as PropType<SizeOptions>, default: () => ({ width: '50px', height: '50px' }) },
    barSize: { type: Object as PropType<SizeOptions>, default: () => ({ width: '310px', height: '40px' }) },
  },
  setup(props, context) {
    let proxy: ComponentPublicInstance<any>;

    const secretKey = ref(''); // 后端返回的ase加密秘钥
    const passFlag = ref(false); // 是否通过的标识
    const backImgBase = ref(''); // 验证码背景图片
    const blockBackImgBase = ref(''); // 验证滑块的背景图片
    const backToken = ref(''); // 后端返回的唯一token值
    const startMoveTime = ref(0); // 移动开始的时间
    const endMovetime = ref(0); // 移动结束的时间
    const tipsBackColor = ref(''); // 提示词的背景颜色
    const tipWords = ref('');
    const captchaId = ref(''); // 后端传递的唯一ID
    const text = ref('');
    const finishText = ref('');
    const setSize = reactive({
      imgHeight: '0',
      imgWidth: '0',
      barHeight: '0',
      barWidth: '0',
    });

    const top = ref(0);
    const left = ref(0);
    const moveBlockLeft = ref<string>('auto');
    const leftBarWidth = ref<string | undefined>(undefined);

    // 移动中样式
    const moveBlockBackgroundColor = ref('#fff');
    const leftBarBorderColor = ref('#ddd');
    const iconColor = ref('#fff');
    const iconClass = ref('icon-right');
    const status = ref(false); // 鼠标状态
    const isEnd = ref(false);		// 是够验证完成
    const showRefresh = ref(true);
    const transitionLeft = ref('');
    const transitionWidth = ref('');
    const startLeft = ref(0);

    const barArea = computed(() => proxy.$el.querySelector('.verify-bar-area')!);

    // 请求背景图片和验证图片
    function getPictrue() {
      reqGet().then(res => {
        if (res.code === 200) {
          backImgBase.value = res.data.mainImage;
          blockBackImgBase.value = res.data.patchImage;
          captchaId.value = res.data.captchaId;
        } else {
          tipWords.value = res.message;
        }
      });
    }

    const refresh = () => {
      showRefresh.value = true;
      finishText.value = '';

      transitionLeft.value = 'left .3s';
      moveBlockLeft.value = '0';

      leftBarWidth.value = undefined;
      transitionWidth.value = 'width .3s';

      leftBarBorderColor.value = '#ddd';
      moveBlockBackgroundColor.value = '#fff';
      iconColor.value = '#000';
      iconClass.value = 'icon-right';
      isEnd.value = false;

      getPictrue();
      setTimeout(() => {
        transitionWidth.value = '';
        transitionLeft.value = '';
        text.value = props.explain;
      }, 300);
    };

    // 鼠标按下 屏幕开始接触
    function start(e: TouchEvent & MouseEvent) {
      e = e || window.event;
      const x = e.touches ? e.touches[0].pageX : e.clientX;

      startLeft.value = Math.floor(x - barArea.value.getBoundingClientRect().left);
      startMoveTime.value = (new Date()).getTime(); // 开始滑动的时间
      if (isEnd.value === false) {
        text.value = '';
        moveBlockBackgroundColor.value = '#337ab7';
        leftBarBorderColor.value = '#337AB7';
        iconColor.value = '#fff';
        e.stopPropagation();
        status.value = true;
      }
    }

    // 鼠标移动
    function move(e: TouchEvent & MouseEvent) {
      e = e || window.event;
      if (!status.value || isEnd.value !== false) return;

      // 兼容PC端 / 移动端
      const x = e.touches ? e.touches[0].pageX : e.clientX;
      const barAreaLeft = barArea.value.getBoundingClientRect().left;

      if (x - startLeft.value > barAreaLeft && x < barAreaLeft + 345 + startLeft.value) {
        let moveBlockLeftTemp = x - barAreaLeft; // 小方块相对于父元素的left值
        if (moveBlockLeftTemp >= barArea.value.offsetWidth - Math.floor(parseInt(props.blockSize.width, 10) / 2) - 2) {
          moveBlockLeftTemp = barArea.value.offsetWidth - Math.floor(parseInt(props.blockSize.width, 10) / 2) - 2;
        }

        if (moveBlockLeftTemp <= 0) {
          moveBlockLeftTemp = 10;
        }

        // 拖动后小方块的left值
        moveBlockLeft.value = `${moveBlockLeftTemp - startLeft.value}px`;
        leftBarWidth.value = `${moveBlockLeftTemp - startLeft.value}px`;
      }
    }

    // 鼠标松开
    const end = async () => {
      endMovetime.value = +(new Date());
      // 判断是否重合;
      if (!status.value || isEnd.value !== false) return;

      status.value = false;
      const moveLeftDistance = parseInt((moveBlockLeft.value || '').replace('px', ''), 10);
      // moveLeftDistance = (moveLeftDistance * 310) / parseInt(setSize.imgWidth, 10);
      const data = {
        x: moveLeftDistance,
        captchaId: captchaId.value,
        width: parseInt(setSize.imgWidth, 10),
      };

      let res;
      try {
        res = await reqCheck(data);
      } catch (e: Error & any) {
        if (e instanceof RequestError) {
          return message.error(e.message);
        }

        // 逻辑错误
        moveBlockBackgroundColor.value = '#d9534f';
        leftBarBorderColor.value = '#d9534f';
        iconColor.value = '#fff';
        iconClass.value = 'icon-close';
        passFlag.value = false;
        setTimeout(() => {
          refresh();
        }, 1000);
        proxy.$parent.$emit('error', proxy);
        tipWords.value = '验证失败';
        setTimeout(() => {
          tipWords.value = '';
        }, 1000);
        return;
      }

      // 验证成功
      moveBlockBackgroundColor.value = '#5cb85c';
      leftBarBorderColor.value = '#5cb85c';
      iconColor.value = '#fff';
      iconClass.value = 'icon-check';
      showRefresh.value = false;
      isEnd.value = true;
      if (props.mode === 'pop') {
        setTimeout(() => {
          proxy.$parent.clickShow = false;
          refresh();
        }, 1500);
      }
      passFlag.value = true;
      tipWords.value = `${((endMovetime.value - startMoveTime.value) / 1000).toFixed(2)}s验证成功`;
      const captchaVerification = secretKey.value ? aesEncrypt(`${backToken.value}---${JSON.stringify({ x: moveLeftDistance, y: 5.0 })}`, secretKey.value) : `${backToken.value}---${JSON.stringify({ x: moveLeftDistance, y: 5.0 })}`;
      setTimeout(() => {
        tipWords.value = '';
        proxy.$parent.closeBox();
        proxy.$parent.$emit('success', { captchaVerification });
      }, 1000);
    };

    function init() {
      text.value = props.explain;
      getPictrue();
      nextTick(() => {
        const { imgHeight, imgWidth, barHeight, barWidth } = resetSize(proxy);
        setSize.imgHeight = imgHeight;
        setSize.imgWidth = imgWidth;
        setSize.barHeight = barHeight;
        setSize.barWidth = barWidth;
        proxy.$parent.$emit('ready', proxy);
      });

      window.removeEventListener('touchmove', e => {
        move(e as any);
      });
      window.removeEventListener('mousemove', e => {
        move(e as any);
      });

      // 鼠标松开
      window.removeEventListener('touchend', () => {
        end();
      });
      window.removeEventListener('mouseup', () => {
        end();
      });

      window.addEventListener('touchmove', e => {
        move(e as any);
      });
      window.addEventListener('mousemove', e => {
        move(e as any);
      });

      // 鼠标松开
      window.addEventListener('touchend', () => {
        end();
      });
      window.addEventListener('mouseup', () => {
        end();
      });
    }

    watch(
      () => props.type,
      () => init(),
    );

    onMounted(() => {
      // 禁止拖拽
      init();
      proxy = getCurrentInstance()!.proxy;
      proxy.$el.onselectstart = () => false;
    });


    return {
      secretKey, // 后端返回的ase加密秘钥
      passFlag, // 是否通过的标识
      backImgBase, // 验证码背景图片
      blockBackImgBase, // 验证滑块的背景图片
      backToken, // 后端返回的唯一token值

      tipsBackColor, // 提示词的背景颜色
      tipWords,
      text,
      finishText,
      setSize,
      top,
      left,
      moveBlockLeft,
      leftBarWidth,
      // 移动中样式
      moveBlockBackgroundColor,
      leftBarBorderColor,
      iconColor,
      iconClass,
      status, // 鼠标状态
      isEnd,		// 是够验证完成
      showRefresh,
      transitionLeft,
      transitionWidth,

      refresh,
      start,
    };
  },
});
