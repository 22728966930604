import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: _ctx.title,
      visible: true,
      width: "600px",
      onCancel: _ctx.handleCancel
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "back",
          size: "middle",
          onClick: _ctx.handleCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("取 消")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          key: "submit",
          size: "middle",
          type: "primary",
          onClick: _ctx.handleOk
        }, {
          default: _withCtx(() => [
            _createTextVNode("确 定")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formData,
          style: {"margin":"20px","text-align":"center"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              name: "templateName",
              rules: [{ pattern: /^.{0,30}$/, message: '模板名称最多支持30个字符', trigger: 'blur' }, { required: true, message: '模板名称不能为空', trigger: 'blur'}]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formData.templateName,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.templateName) = $event)),
                  placeholder: "请输入模板名称",
                  style: {"width":"300px"}
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["rules"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["title", "onCancel"])
  ]))
}