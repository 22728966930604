
import { defineComponent, computed, reactive, ref, onMounted, onUnmounted, onActivated } from 'vue';
import { message, Modal } from 'ant-design-vue';
import type { TableColumn } from '@/components/Table';
import router from '@/router';
import { useRoute } from 'vue-router';
import { dispatchDownload } from '@/utils/illuminate';
import Axiox, { CancelTokenSource } from 'axios';
import { useState } from '@/store';
import moment from 'moment';


import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'ExecuteLog',
  components: {

  },
  setup(props, ctx) {
    const quotaType = sessionStorage.getItem('quota_type');
    const route = useRoute();
    const state = useState();
    const query = { ...route.query };
    const tableRef = ref<any>(null as any);
    const totalNum = ref<string | number>(0);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态
    const filterParams = reactive<{ templateId: number | undefined }>({ templateId: undefined });
    const templateList = ref<any[]>([]);
    let init = true;
    const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId')) || state.enterprise.value.enterpriseId;


    const routes = [{
      name: 'main.staff.quota_adjustment',
      breadcrumbName: quotaType === 'general' ? '普通额度调整' : '定期额度调整',
    }, {
      name: 'main.staff.execute_log',
      breadcrumbName: '执行记录',
    }];

    // 表格列表
    const tableColumns = computed<TableColumn[]>(() => {
      const columns: TableColumn[] = [
        { title: '日期', dataIndex: 'executionTime', width: '180px' },
        { title: '模版名称', dataIndex: 'templateName', ellipsis: true, width: '150px', customRender: ({ text, record }) => `${text}(${record.templateId})` },
        { title: '操作人', dataIndex: 'operatorUserName' },
        { title: '部门数', dataIndex: 'departmentSum' },
        { title: '执行人数', dataIndex: 'employeeCount' },
        { title: '成功人数', dataIndex: 'successSum' },
        { title: '失败人数', dataIndex: 'failSum' },
        { title: '执行状态', dataIndex: 'state', slots: { customRender: 'state' } },
        { title: '操作', dataIndex: 'action', width: '150px', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return columns;
    });
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    // 获取模版数据
    const loadTableData = async (parameter: any) => {
      try {
        totalNum.value = '---';
        const res = await request.get(createApiUrl('/newlinkSass/quota/ar/batch/execute/info/page'), { params: { 
          ...parameter,
          ...filterParams,
          templateType: quotaType === 'general' ? 1 : 2,
          templateId: init ? (Number(query.id) || undefined) : filterParams.templateId,
          enterpriseId,
        } });
        totalNum.value = res.data.total;
        const list = res.data.list.map((item :any) => ({ ...item, executionTime: moment(item.executionTime).format('YYYY-MM-DD HH:mm:ss') })); // 当前Table数据
        dataPage.value = list;
        dataState.value = true; // 数据第一次加载
        return { ...res.data, list };
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };
    
    // 导出
    const onExport = async (record: any) => {
      let res: any;
      message.success('开始下载');
      try {
        const url = quotaType === 'general' ? '/excel/down/ar/quota/normal/result' : '/excel/down/ar/quota/cycle/result';
        res = await request.get(createApiUrl(`/newlinkSass${url}`), {
          responseType: 'blob',
          params: { executeId: record.id },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload('执行记录.xlsx', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      message.success('下载完成');
    };

    // 模糊搜索
    const handleSearch = (value: any) => {
      init = false;
      if (value) {
        const params = { templateName: value, templateType: quotaType === 'general' ? 1 : 2 };
        request.get(createApiUrl('/newlinkSass/quota/ar/get/template/by-names-and-type'), { params: { ...params, enterpriseId } }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          templateList.value = data;
          if (query.id) {
            filterParams.templateId = Number(query.id);
          }
        });
      }
    };
    
    onActivated(() => {
      handleSearch(query.templateName || '');
    });

    onMounted(() => {
      handleSearch(query.templateName || '');
    });

    return {
      tableColumns,
      tableRef,
      filterParams,
      templateList,
      routes,
      loadTableData,
      onExport,
      handleSearch,
      onRefreshTable,
    };
  },
});
