
import { defineComponent, reactive, ref, computed } from 'vue';
import copy from 'copy-to-clipboard';
import { message } from 'ant-design-vue';
import { useState } from '@/store';

export default defineComponent<{}, {}, {
  visible: boolean;
  record: any;
}, {}, any>({
  name: 'ViewLinkModal',
  setup(props, ctx) {
    const state = useState();
    const visible = ref(false);
    const record = ref({
      enterpriseName: '',
      invitationCode: '',
      url: '',
    });

    const view = (item: any) => {
      record.value = item;
      visible.value = true;
    };

    const QrCodeRef = ref<any>(null as any);
    const onDownloadQrcode = () => {
      const dataUrl = QrCodeRef.value.getDataURL();
      // 生成一个a元素
      const a = document.createElement('a');
      const event = new MouseEvent('click');
      a.download = 'QrCode.png';
      // 将生成的URL设置为a.href属性
      a.href = dataUrl;
      // 触发a的单击事件
      a.dispatchEvent(event);
    };

    const onCopyUrl = () => {
      // Copy with options
      const copyRes = copy(`${state.enterprise.value.enterpriseName} 邀请您使用能链企业版 -- 加油更快捷，报销更方便 \n点击链接: ${record.value.url}`);

      if (copyRes) {
        message.success('已复制');
      } else {
        message.error('复制失败');
      }
    };

    return {
      visible,
      record,

      view,
      onCopyUrl,

      QrCodeRef,
      onDownloadQrcode,
    };
  },
});
