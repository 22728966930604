import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "departmentMenu" }
const _hoisted_2 = { class: "departmentMenu__departmentList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_ctx.departmentList.length)
        ? (_openBlock(), _createBlock(_component_a_tree, {
            key: 0,
            modelValue: _ctx.checkedKeys,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedKeys) = $event)),
            "show-icon": false,
            "show-line": true,
            "auto-expand-parent": false,
            "expanded-keys": _ctx.expandedDepartmentIds,
            "selected-keys": _ctx.selectedDepartmentIds,
            "tree-data": _ctx.departmentList,
            onSelect: _ctx.onSelectItem,
            onExpand: _ctx.onExpandDepartment
          }, null, 8, ["modelValue", "expanded-keys", "selected-keys", "tree-data", "onSelect", "onExpand"]))
        : (!_ctx.withDefault)
          ? (_openBlock(), _createBlock(_component_a_empty, {
              key: 1,
              description: "暂无部门",
              image: _ctx.emptyImage
            }, null, 8, ["image"]))
          : _createCommentVNode("", true)
    ])
  ]))
}