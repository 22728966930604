
import { defineComponent, onMounted, ref, PropType } from 'vue';
import { RowDetail } from '@/components/QuotaAdjustment/utils';
import RegularQuota from './regular.vue';


export default defineComponent({
  name: 'QuotaMange',
  components: {
    RegularQuota,
  },
  props: {
    rowDetail: { 
      type: Object as PropType<RowDetail>, 
      default: () => ({}),
    }, 
  },
  emits: ['complete'],
  setup(props, ctx) {
    const RegularQuotaRef = ref<any>(null as any);


    const closeModal = (reloadList = false) => {
      RegularQuotaRef.value.closeModal();
      ctx.emit('complete', reloadList);
    };

    return {
      RegularQuotaRef,
      closeModal,
    };
  },
});
