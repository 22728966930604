
import { defineComponent, ref, onMounted } from 'vue';
import { message, Empty } from 'ant-design-vue';
import { settleTree } from '@/utils/illuminate';

import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import { useStore } from 'vuex';
import { DepartmentItem } from '@/utils/constants';


export default defineComponent({
  name: 'DepartmentMenuNew',
  props: {
    withDefault: { type: Boolean, default: false },
  },
  emits: [
    'changeDepartment',
  ],
  setup(props, ctx) {
    const store = useStore();
    const authority = ref(store.state.User.authority);
    const state = useState();
    const departmentList = ref<(DepartmentItem & any)[]>([]);
    const expandedDepartmentIds = ref<number[]>([]);
    const selectedDepartmentIds = ref<number[]>([]);
    // 获取部门信息
    const loadEnterpriseDepartment = async () => {
      let res: any;
      const enterpriseId = Number(sessionStorage.getItem('currentEnterpriseId'));
      try {
        res = await request.get(
          createApiUrl('/newlinkSass/enterprise/find-enterprise-department'),
          { params: { enterpriseId: enterpriseId || state.enterprise.value.enterpriseId } },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      departmentList.value = settleTree(res.data.departmentList, { key: 'id', title: 'departmentName' }, 'departmentList');

      if (props.withDefault && authority.value === 0) {
        departmentList.value.unshift({
          key: 0,
          title: '全部',
        });
      }
    };

    const onChangeDepartment = (departmentId: number, current: any) => {
      ctx.emit('changeDepartment', departmentId, current);
    };

    const onSelectItem = (keys: number[], e: any) => {
      console.log(keys, e);
      selectedDepartmentIds.value = keys;
      if (!e.selectedNodes[0]) return;
      onChangeDepartment(keys[0], e.selectedNodes[0]?.props);
    };

    const onExpandDepartment = (keys: number[]) => {
      expandedDepartmentIds.value = keys;
    };


    onMounted(async () => {
      loadEnterpriseDepartment();
    });


    return {
      
      checkedKeys: [],

      onSelectItem,
      onExpandDepartment,
      departmentList,
      expandedDepartmentIds,
      selectedDepartmentIds,

      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
});
