import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "verifybox-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isPop ?'mask':'')
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isPop ?'verifybox':''),
      style: _normalizeStyle({maxWidth: `${parseInt(_ctx.imgSize.width)+30}px`})
    }, [
      (_ctx.isPop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(" 请完成安全验证 "),
            _createElementVNode("span", {
              class: "verifybox-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeBox && _ctx.closeBox(...args)))
            }, [
              _createVNode(_component_app_icon, { type: "close" })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "verifybox-bottom",
        style: _normalizeStyle({padding: _ctx.isPop ? '15px' : '0'})
      }, [
        (_ctx.componentType)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), {
              key: 0,
              ref: "instance",
              "captcha-type": _ctx.captchaType,
              type: _ctx.verifyType,
              figure: _ctx.figure,
              arith: _ctx.arith,
              mode: _ctx.mode,
              "v-space": _ctx.vSpace,
              explain: _ctx.explain,
              "img-size": _ctx.imgSize,
              "block-size": _ctx.blockSize,
              "bar-size": _ctx.barSize
            }, null, 8, ["captcha-type", "type", "figure", "arith", "mode", "v-space", "explain", "img-size", "block-size", "bar-size"]))
          : _createCommentVNode("", true)
      ], 4)
    ], 6)
  ], 2)), [
    [_vShow, _ctx.showBox]
  ])
}