
import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { formatDate } from '@/core/filters';

export default {
  name: 'AlreadyJoinModal',
  setup() {
    const visible = ref(false);

    const total = ref(0);
    const defaultParams = {
      state: 'SUCCESS', // 邀请状态 WAIT 待加入, SUCCESS 已加入, FAIL 加入失败;
      invitationId: '', // 链接的主键id
      enterpriseId: '', // 链接的主键id
      chanelType: 3, // 渠道类型：1,手机，2邮件，3链接
    };

    const show = (record: any) => {
      visible.value = true;
      defaultParams.invitationId = record.id; // 邀请码
      defaultParams.enterpriseId = record.enterpriseId;
    };

    const tableColumns: TableColumn[] = [
      { title: '员工姓名', dataIndex: 'userName' },
      { title: '手机号码', dataIndex: 'phone' },
      { title: '加入时间', dataIndex: 'invitationDateTime', slots: { customRender: 'dateTime' } },
    ];

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/invitation/invitation-find-user-list'), {
        params: { ...parameter, ...defaultParams },
      })
        .then((res: any) => {
          total.value = res.data.total;
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    // 链接邀请的table
    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    return {
      total,

      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      formatDate,

      visible,
      show,
    };
  },
};
