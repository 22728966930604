import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { settleTree } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useState } from '@/store';

export function useDepartment() {
  const store = useStore();
  const departmentTree = ref<any[]>([]);

  const loadDepartmentTree = async (enterpriseId?: number) => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), {
        params: { enterpriseId: enterpriseId || store.state.User.currentEnterpriseId || store.state.User.enterprise?.enterpriseId },
      });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    departmentTree.value = settleTree(res.data.departmentList, {
      value: 'id',
      departmentId: 'id',
      title: 'departmentName',
      label: 'departmentName',
    }, 'departmentList');
  };

  const departmentDetail = reactive({
    departmentName: '',
    parentDepartmentId: 0,
    managerUserCode: 0,
    defaultFlag: 0,
    parent: [{ name: 'd' }],
  });

  // 加载企业部门详情
  const loadDepartmentDetail = async (id: number) => {
    let resData: any;

    try {
      ({ data: resData } = await request.get(
        createApiUrl('/newlinkSass/department/find-department-detail'), { params: { id } },
      ));
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    Object.assign(departmentDetail, resData);
  };

  // 删除企业
  const delDepartment = (id: number) => request.delete(
    createApiUrl('/newlinkSass/department/update-department-state'), { data: { id } },
  );

  return {
    departmentTree,
    loadDepartmentTree,

    departmentDetail,
    loadDepartmentDetail,
    delDepartment,
  };
}
