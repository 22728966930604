
import { defineComponent, computed, ref, watchEffect } from 'vue';
import VerifySlide from './component/VerifySlide.vue';
import VerifyPoints from './component/VerifyPoints.vue';

/**
 * Verify 验证码组件
 *
 * @description 分发验证码使用
 * */
export default defineComponent({
  name: 'Vue2Verify',
  components: {
    VerifySlide,
    VerifyPoints,
  },
  props: {
    captchaType: { type: String, required: true },
    figure: { type: Number, default: 0 },
    arith: { type: Number, default: 0 },
    mode: { type: String, default: 'pop' },
    vSpace: { type: Number, default: 0 },
    explain: { type: String, default: '' },
    imgSize: { type: Object, default: () => ({ width: '310px', height: '155px' }) },
    blockSize: { type: Object, default: () => ({}) },
    barSize: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const clickShow = ref(false);
    const verifyType = ref('1');
    const componentType = ref('VerifySlide');
    const instance = ref(null as any);

    const isPop = computed(() => (props.mode === 'pop'));
    const showBox = computed(() => (isPop.value ? clickShow.value : true));

    // 刷新
    const refresh = () => {
      if (instance.value.refresh) {
        instance.value.refresh();
      }
    };

    const closeBox = () => {
      clickShow.value = false;
      refresh();
    };

    const show = () => {
      if (isPop.value) {
        clickShow.value = true;
      }
    };

    watchEffect(() => {
      switch (props.captchaType) {
        case 'blockPuzzle':
          verifyType.value = '2';
          componentType.value = 'VerifySlide';
          break;
        case 'clickWord':
          verifyType.value = '';
          componentType.value = 'VerifyPoints';
          break;
        default:
          break;
      }
    });

    return {
      clickShow,
      verifyType,
      componentType,
      instance,
      showBox,
      isPop,

      closeBox,
      show,

    };
  },
});
