
import { RightOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'BusinessCard',
  components: {
    RightOutlined,
  },
  setup() {
    const store = useStore();
    const cardData = computed(() => (store.state.User.businessCardDetail));
    // const cardData = ref(null);
    const energyType = computed(() => cardData.value.energyType && cardData.value.energyType.split(',').map((item: string) => {
      switch (item) {
        case 'gasoline': return '汽油';
        case 'diesel_oil': return '柴油';
        case 'natural_gas': return '天然气';
        case 'electricity': return '电';
        default: break;
      }
    }).join('、'));

    // 是否显示当前入口
    const isShow = computed(() => (cardData.value && ((cardData.value.cardType === 2 && (cardData.value.couponTimes - cardData.value.usageCount)) || (cardData.value.cardType === 1 && cardData.value.discountTimesFlag && (cardData.value.discountTimes - cardData.value.usageCount)) || (cardData.value.cardType === 1 && !cardData.value.discountTimesFlag))));

    const getEnterpriseCardDetailFun = () => {
      try {
        // getEnterpriseCardDetail().then(res => {
        //   cardData.value = res.data;
        // });
        store.dispatch('User/getBusinessCardDetail');
      } catch (e: Error & any) {
        store.dispatch('User/setBusinessCardDetail', null);
      }
    };

    onMounted(() => {
      getEnterpriseCardDetailFun();
    });

    return {
      cardData,
      energyType,
      isShow,
    };
  },
});
