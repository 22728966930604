
import { defineComponent, computed, onMounted, reactive, ref, watch, nextTick, toRefs, watchEffect, getCurrentInstance, ComponentPublicInstance } from 'vue';
import { reqGet, reqCheck } from '@/apis/invitation';
import { resetSize } from '../utils/util';
import { aesEncrypt } from '../utils/ase';

type PointPosition = {
  x: number;
  y: number;
};

type CanvasOptions = {
  imgHeight: string;
  imgWidth: string;
  barHeight: string;
  barWidth: string;
}

/**
 * VerifyPoints
 * @description 点选
 */
export default defineComponent({
  name: 'VerifyPoints',
  props: {
    // 弹出式pop，固定fixed
    mode: { type: String, default: 'fixed' },
    captchaType: { type: String, default: 'captchaType' },
    // 间隔
    vSpace: { type: Number, default: 5 },
    imgSize: { type: Object, default: () => ({ width: '310px', height: '155px' }) },
    barSize: { type: Object, default: () => ({ width: '310px', height: '40px' }) },
  },
  setup(props, context) {
    const { mode, captchaType, vSpace, imgSize, barSize } = toRefs(props);
    let proxy: ComponentPublicInstance;
    const secretKey = ref(''); // 后端返回的ase加密秘钥
    const checkNum = ref(3); // 默认需要点击的字数
    const fontPos = reactive([]); // 选中的坐标信息
    const checkPosArr = ref<PointPosition[]>([]); // 用户点击的坐标
    const num = ref(1); // 点击的记数
    const pointBackImgBase = ref(''); // 后端获取到的背景图片
    const poinTextList = ref([]); // 后端返回的点击字体顺序
    const backToken = ref(''); // 后端返回的token值
    const setSize = reactive<CanvasOptions>({ imgHeight: '0', imgWidth: '0', barHeight: '0', barWidth: '0' });
    const tempPoints = ref<PointPosition[]>([]);
    const text = ref('');
    const barAreaColor = ref('initial');
    const barAreaBorderColor = ref('initial');
    const showRefresh = ref(true);
    const bindingClick = ref(true);

    // 请求背景图片和验证图片
    function getPictrue() {
      reqGet().then(res => {
        if (res.code === 200) {
          pointBackImgBase.value = res.data.originalImageBase64;
          backToken.value = res.data.token;
          secretKey.value = res.data.secretKey;
          poinTextList.value = res.data.wordList;
          text.value = `请依次点击【${poinTextList.value.join(',')}】`;
        } else {
          text.value = res.data.message;
        }
      });
    }

    // 坐标转换函数
    const pointTransfrom = (pointArr: PointPosition[], imgSize: CanvasOptions) => pointArr.map(p => {
      const x = Math.round((310 * p.x) / parseInt(imgSize.imgWidth, 10));
      const y = Math.round((155 * p.y) / parseInt(imgSize.imgHeight, 10));
      return { x, y };
    });

    // 获取坐标
    const getMousePos = (e: MouseEvent) => ({ x: e.offsetX, y: e.offsetY });

    // 创建坐标点
    const createPoint = (pos: PointPosition) => {
      tempPoints.value.push({ ...pos });
      return num.value + 1;
    };

    const refresh = () => {
      tempPoints.value.splice(0, tempPoints.value.length);
      barAreaColor.value = '#000';
      barAreaBorderColor.value = '#ddd';
      bindingClick.value = true;
      fontPos.splice(0, fontPos.length);
      checkPosArr.value.splice(0, checkPosArr.value.length);
      num.value = 1;
      getPictrue();
      text.value = '验证失败';
      showRefresh.value = true;
    };
    const init = () => {
      // 加载页面
      fontPos.splice(0, fontPos.length);
      checkPosArr.value.splice(0, checkPosArr.value.length);
      num.value = 1;
      getPictrue();
      nextTick(() => {
        const { imgHeight, imgWidth, barHeight, barWidth } = resetSize(proxy);
        setSize.imgHeight = imgHeight;
        setSize.imgWidth = imgWidth;
        setSize.barHeight = barHeight;
        setSize.barWidth = barWidth;
        proxy.$parent!.$emit('ready', proxy);
      });
    };

    const canvas = ref(null);
    const canvasClick = async (e: MouseEvent) => {
      checkPosArr.value.push(getMousePos(e));
      if (num.value === checkNum.value) {
        num.value = createPoint(getMousePos(e));
        // 按比例转换坐标值
        const arr = pointTransfrom(checkPosArr.value, setSize);
        checkPosArr.value.length = 0;
        checkPosArr.value.push(...arr);

        // var flag = this.comparePos(this.fontPos, this.checkPosArr);
        // 发送后端请求
        const captchaVerification = secretKey.value ? aesEncrypt(`${backToken.value}---${JSON.stringify(checkPosArr)}`, secretKey.value) : `${backToken.value}---${JSON.stringify(checkPosArr)}`;
        const data = {
          captchaType: captchaType.value,
          pointJson: secretKey.value ? aesEncrypt(JSON.stringify(checkPosArr), secretKey.value) : JSON.stringify(checkPosArr),
          token: backToken.value,
        };

        try {
          await reqCheck(data);
        } catch (e: Error & any) {
          proxy.$parent!.$emit('error', proxy);
          barAreaColor.value = '#d9534f';
          barAreaBorderColor.value = '#d9534f';
          text.value = '验证失败';
          setTimeout(() => {
            refresh();
          }, 700);
          return;
        }

        barAreaColor.value = '#4cae4c';
        barAreaBorderColor.value = '#5cb85c';
        text.value = '验证成功';
        bindingClick.value = false;
        if (mode.value === 'pop') {
          setTimeout(() => {
            (proxy.$parent! as any).clickShow = false;
            refresh();
          }, 1500);
        }
        proxy.$parent!.$emit('success', { captchaVerification });
      }

      if (num.value < checkNum.value) {
        num.value = createPoint(getMousePos(e));
      }
    };

    onMounted(() => {
      // 禁止拖拽
      init();
      proxy = getCurrentInstance()!.proxy!;
      proxy.$el.onselectstart = () => false;
    });

    return {
      secretKey,
      checkNum,
      fontPos,

      num,
      pointBackImgBase,
      backToken,
      setSize,
      tempPoints,
      text,
      barAreaColor,
      barAreaBorderColor,
      showRefresh,
      bindingClick,
      init,
      canvas,
      canvasClick,
      getMousePos,
      createPoint,
      refresh,
      getPictrue,
      pointTransfrom,
    };
  },
});
