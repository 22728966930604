
import { defineComponent, onMounted, ref, getCurrentInstance } from 'vue';
import { RowDetail } from '@/components/QuotaAdjustment/utils';
import { Modal, message } from 'ant-design-vue';
import { useEcho } from '@/utils/echo';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import AvailableQuota from './available.vue';
import RegularQuota from './regular.vue';


export default defineComponent({
  name: 'Modal',
  components: {
    AvailableQuota,
    RegularQuota,
  },
  emits: ['complete'],
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const echo = useEcho();
    const visible = ref(false);
    const signValue = ref('in');
    const numValue = ref('');
    const switchValue = ref(1);

    const rowDetail = ref<{ 
      licensePlate?: string, 
      vin?: string, 
      modalType: string,
      vehicleNo?: string,
      enterpriseId?: number,
      operatorCode?: string,
    }>({
      modalType: '',
    });
    const modalType = ref('');
    const quotaFlag = ref(0);

    const AvailableQuotaRef = ref<any>(null as any);
    const RegularQuotaRef = ref<any>(null as any);

    echo('quotaAdjust', ({ type, val }: { type: string, val: RowDetail }) => {
      console.log(type, val);
      modalType.value = type;
      visible.value = true;
      rowDetail.value = { ...val, modalType: type };
    });
    const showModal = ({ type, val }: { type: string, val: RowDetail }) => {
      console.log(type, val);
      modalType.value = type;
      visible.value = true;
      quotaFlag.value = val.quotaFlag || 0;
      rowDetail.value = { ...val, modalType: type };
      if (type === 'person') instance?.$sensors.track('es_saas_staff_quota_popup_view', { page_name: '员工_额度管理弹窗_浏览' });
      if (type === 'department') instance?.$sensors.track('es_saas_organisation_quota_popup_view', { page_name: '组织架构_部门额度管理弹窗_浏览' });
    };

    const closeModal = (reloadList = false) => {
      visible.value = false;
      signValue.value = 'in';
      switchValue.value = 1;
      quotaFlag.value = 0;
      AvailableQuotaRef.value.closeModal();
      RegularQuotaRef.value.closeModal();
      reloadList && ctx.emit('complete');
    };

    const onSubmit = () => {
      Modal.confirm({
        title: '操作确认',
        content: `将${rowDetail.value.licensePlate || rowDetail.value.vin || ''}账户设置为”无限“额度，确认调整后将立刻生效。`,
        okText: '确认调整',
        onOk: async () => {
          const params = { vehicleNo: rowDetail.value.vehicleNo, enterpriseId: rowDetail.value.enterpriseId, operatorCode: rowDetail.value.operatorCode };
          const res = await request.post(createApiUrl('/newlinkSass/quota/vr/openUnlimitedBalance'), { ...params });
          if (res && res.code === 200) { 
            message.success('操作成功');
            closeModal(true);
          }
        },
      });
    };

    const onChangeSwitch = (num: number) => {
      switchValue.value = num;
    };

    return {
      showModal,
      closeModal,

      modalType,
      visible,

      rowDetail,

      signValue,
      onSubmit,
      numValue,
      quotaFlag,

      switchValue,

      onChangeSwitch,

      AvailableQuotaRef,
      RegularQuotaRef,
    };
  },
});
