import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd8123e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "departmentMenu" }
const _hoisted_2 = { style: {"text-align":"center"} }
const _hoisted_3 = { class: "department-header" }
const _hoisted_4 = { class: "departmentMenu__departmentList" }
const _hoisted_5 = { class: "department-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_DoubleLeftOutlined = _resolveComponent("DoubleLeftOutlined")!
  const _component_PlusCircleOutlined = _resolveComponent("PlusCircleOutlined")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input, {
        value: _ctx.userCode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userCode) = $event)),
        type: "text",
        placeholder: "用户姓名 / 手机号码",
        "allow-clear": "",
        onChange: _ctx.debouncedGetList
      }, null, 8, ["value", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        style: {"cursor":"pointer"},
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backDepartment && _ctx.backDepartment(...args)))
      }, [
        _createVNode(_component_DoubleLeftOutlined),
        _createTextVNode("返回")
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.currentDepartment.title), 1)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_ctx.list.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "member-item"
            }, [
              _createElementVNode("span", null, _toDisplayString(item.userName), 1),
              _createVNode(_component_PlusCircleOutlined, {
                onClick: ($event: any) => (_ctx.operateMember( 'add', item))
              }, null, 8, ["onClick"])
            ]))
          }), 128))
        : (!_ctx.withDefault)
          ? (_openBlock(), _createBlock(_component_a_empty, {
              key: 1,
              description: "暂无员工",
              image: _ctx.emptyImage
            }, null, 8, ["image"]))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_a_button, {
        type: "primary",
        shape: "round",
        size: "middle",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.operateMember('all', _ctx.list)))
      }, {
        default: _withCtx(() => [
          _createTextVNode("全部添加")
        ]),
        _: 1
      })
    ])
  ]))
}