import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import router from '@/router';

export function useInvite() {
  const route = useRoute();
  const inviteDetail = ref({});


  // 邀请员工
  const onGotoInvite = async () => {
    // let res: any;
    // try {
    //   res = await axios.get(createApiUrl('/newlinkSass/invitation/invitation-find-list'), { params: { pageSize: 2, pageNo: 1 } });
    // } catch (e: Error & any) {
    //   return message.error(e.message);
    // }

    // router.push({ name: res.data.total ? 'main.staff.invite_link' : 'main.staff.invite' });
    router.push({ name: 'main.staff.invite', query: { goback: route.fullPath } });
  };

  const loadInviteDetail = async (id: number, enterpriseId: number) => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/invitation/invitation-find-detail'), {
        params: { id, enterpriseId },
      });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    inviteDetail.value = res.data;
  };

  return {
    inviteDetail,
    loadInviteDetail,

    onGotoInvite,
  };
}
