
import { ref, watch, PropType, defineComponent, inject, Ref } from 'vue';
import { formatCny } from '@/utils/illuminate';
import { message } from 'ant-design-vue';

import InputNumber from '@/components/InputNumber/InputNumber.vue';
import { request } from '@/utils/request';
import { createApiUrl, toApiUnit } from '@/utils/utils';
import { RowDetail } from './utils';

export default defineComponent({
  components: { InputNumber },
  props: {
    rowDetail: { type: Object as PropType<RowDetail>, default: () => ({}) },
  },
  emits: ['closeModal'],
  setup(props, ctx) {
    const numValue = ref('');
    const resNumValue: any = ref(`${props.rowDetail!.availableAmount / 100}` || '');
    const availableList = [100, 200, 500, 1000];
    const quotaRadio = ref(1);

    const changeQuotaRadio = (e: any) => {
      resNumValue.value = e.target.value ? `${props.rowDetail.availableAmount / 100 + Number(numValue.value)}` : `${props.rowDetail.availableAmount / 100 - Number(numValue.value)}`;
      if (resNumValue.value < 0) resNumValue.value = 0;
    };

    watch(() => props.rowDetail, newValue => {
      quotaRadio.value = 1;
      resNumValue.value = formatCny((newValue!.availableAmount / 100).toString());
      // numValue.value = formatCny((newValue!.availableAmount / 100).toString());
    });

    watch(numValue, (newValue, oldValue) => {
      if (!newValue || (newValue.length && newValue.indexOf('.') === newValue.length - 1)) {
        resNumValue.value = props.rowDetail.availableAmount / 100;
        return;
      }
      const moneyReg = /(^[1-9]([0-9]{0,5})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]{1,6}\.[0-9]{1,2}?$)/;
      if (!moneyReg.test(newValue)) numValue.value = oldValue;
      resNumValue.value = quotaRadio.value ? formatCny((props.rowDetail.availableAmount / 100 + Number(newValue)).toString()) : formatCny((props.rowDetail.availableAmount / 100 - Number(newValue)).toString());
      if (resNumValue.value < 0) resNumValue.value = 0;
    });

    const changeNum = (num: number) => {
      numValue.value = formatCny((num).toString());
      +numValue.value <= 0 && (numValue.value = '0.00');
      resNumValue.value = quotaRadio.value ? formatCny((props.rowDetail.availableAmount / 100 + num).toString()) : formatCny((props.rowDetail.availableAmount / 100 - num).toString());
    };

    const closeModal = () => {
      numValue.value = '';
    };

    const onCancel = () => {
      ctx.emit('closeModal', true);
    };

    const isSubmit = ref(true);
    const onSubmit = async () => {
      if (!numValue.value) return message.error('请输入金额');

      try {
        if (isSubmit.value) {
          isSubmit.value = false;
          let res;
          const params = {
            direction: quotaRadio.value === 1 ? 'in' : 'out', // 调整方向（out：-，in：+）
            adjustmentType: 1, // 调整类型（1.按数额，2.调整至）
            beforeNormalBalance: props.rowDetail!.normalBalance, // 调整前普通余额
            beforePeriodicBalance: props.rowDetail!.periodicBalance, // 调整前定期余额
          };

          if (props.rowDetail!.modalType === 'car') {
            res = await request.put(createApiUrl('/newlinkSass/quota/vr/update/available/amount'), {
              ...params,
              adjustmentAmount: toApiUnit(+numValue.value), // 调整金额
              vehicleNo: props.rowDetail!.vehicleNo, // 车辆编号
            });
          } else if (props.rowDetail!.modalType === 'person') {
            res = await request.put(createApiUrl('/newlinkSass/quota/ar/update/available/amount'), {
              ...params,
              adjustmentAmount: toApiUnit(+numValue.value),
              userCode: props.rowDetail!.userCode, // 员工编号
            });
          } else if (props.rowDetail!.modalType === 'department') {
            res = await request.put(createApiUrl('/newlinkSass/quota/dr/update/available/amount'), {
              ...params,
              beforePeriodicBalance: props.rowDetail!.periodRuleBalance,
              adjustmentAmount: toApiUnit(+numValue.value),
              departmentId: props.rowDetail!.id, // 部门编号
            });
          }
          if (res && res.code === 200) message.success('操作成功');
        }
      } catch (e: Error & any) {
        message.error(e.message);
      } finally {
        ctx.emit('closeModal', true);
        isSubmit.value = true;
      }
    };

    return {
      quotaRadio,
      changeQuotaRadio,

      closeModal,

      availableList,
      numValue,
      resNumValue,
      changeNum,
      isSubmit,
      onSubmit,
      onCancel,
    };
  },
});
